import React from 'react';
import values from 'lodash/values';

import TreeNode from './TreeNode';

export default class Tree extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      nodes: props.nodes,
    };
   }

  getRootNodes = () => {
    const { nodes } = this.state;
    return values(nodes).filter(node => node.isRoot === true);
  }

  getChildNodes = (node) => {
    const { nodes } = this.state;
    if (!node.children) return [];
    return node.children.map(path => nodes[path]);
  }

  onToggle = (node) => {
    const { nodes } = this.state;
    nodes[node.path].isOpen = !node.isOpen;
    this.setState({ nodes });
  }

  onNodeSelect = node => {
    if (node.type === "folder")
        this.onToggle(node)
    if (node.type === "file") {
        if (navigator.clipboard !== undefined){
          navigator.clipboard.writeText(node.data);
          alert(node.data + " Copied to clipboard !");
        }
    }
  }

  render() {
    const rootNodes = this.getRootNodes();
    return (
      <div>
        { rootNodes.map(node => (
          <TreeNode
            node={node}
            getChildNodes={this.getChildNodes}
            onToggle={this.onToggle}
            onNodeSelect={this.onNodeSelect}
            key= {this.uuidv4()}
          />
        ))}
      </div>
    )
  }

  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

}

Tree.propTypes = {
  //onSelect: PropTypes.func.isRequired,
};