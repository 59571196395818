/*
**  Powered by WOPR Team's development
*/


/*  AUTH  */
export const SIGNIN = 'SIGNIN';
export const SIGNOUT = 'SIGNOUT';
export const CHECK_AUTH = 'CHECK_AUTH';

/*  ROUTING  */
export const SWITCH_TAB = 'SWITCH_TAB';
export const SET_REDIRECTED_PATH = 'SET_REDIRECTED_PATH';