/*
**  Powered by WOPR Team's development
*/


import	React						from "react";
import	TabRoutes					from "./TabRoutes";
import	{ Provider }				from "react-redux";
import	store						from "../../store"


class Root extends React.Component {

	render() {
		return (
			<Provider store={store}>
				<TabRoutes/>
			</Provider>
		);
	}
}


export default Root;
