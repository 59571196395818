/*
**  Powered by WOPR Team's development
*/


import  { CHECK_AUTH, SIGNIN, SIGNOUT }     from '../actions/types';


let init = {
    isAuthenticated: false,
};


export default function(state = init, action) {
    switch (action.type) {
        case SIGNIN: {
            console.debug("REDUCERS (obj action):");
            console.debug(action);
            const { token } = action.payload;
            localStorage.setItem('auth_token', token);
            return {
                ...state,
                isAuthenticated: true,
            };
        }
        case SIGNOUT: {
            localStorage.removeItem('auth_token');
            return {
                ...state,
                isAuthenticated: false,
            };
        }
        case CHECK_AUTH: {
            return {
                ...state,
                isAuthenticated: !!localStorage.getItem('auth_token'),
            }
        }
        default:
            return state;
    }
}
