/*
**  Powered by WOPR Team's development
*/


import  React, { Component }                from 'react'
import  { Divider }                         from 'semantic-ui-react'
import  ModulesList                         from "./ModulesList";
import  NavBarLayout                        from "../../layouts/NavBarLayout"
import  { switchTab }                       from "../../../store/actions/routing";
import  { connect }                         from "react-redux"
import	{ Switch, Route }                   from "react-router-dom";
import  NewModule                           from "./NewModule";



class Modules extends Component {

    componentDidMount() {
        this.props.switchTab('modules');
        this.props.history.push('/modules');
    }

    render() {
        return (
            <NavBarLayout>
                <Divider hidden />
                <Switch>
                    <Route exact path="/modules" component={ModulesList} />
                    <Route path="/modules/new" component={NewModule} />
                </Switch>
                <Divider hidden />
            </NavBarLayout>
        )
    }
}


export default connect((state) => { return { routing: state.routing }}, { switchTab })(Modules);
