/*
**  Powered by WOPR Team's development
*/

class AlohomoraCTR {

    static async create(name, port) {

        let alohomora_base_url = process.env.REACT_APP_ALOHOMORA_IP;
        console.debug("CREATE a new reverse shell session");
        console.debug("name : " + name);
        console.debug("port : " + port);
        try {
            var xhr = new XMLHttpRequest()
            let response = "";
            xhr.open('POST', alohomora_base_url + '/start_rs', true)
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
            xhr.send(JSON.stringify({ "name": name, "port": port }))

        } catch (err) {
            console.error(err);
            return false;
        }
    }

    static async delete(name) {

        let alohomora_base_url = process.env.REACT_APP_ALOHOMORA_IP;
        console.debug("DELETE a reverse shell session");
        console.debug("name : " + name);
        try {
            var xhr = new XMLHttpRequest()
            let response = "";
            xhr.open('DELETE', alohomora_base_url + '/del_session/' + name, true)
            xhr.send()

            return response.data;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    static download_traces(name) {

        let alohomora_base_url = process.env.REACT_APP_ALOHOMORA_IP;
        console.debug("GET traces for session " + name);
        try {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', alohomora_base_url + '/dl_traces/' + name, true)
            xhr.send()

            return xhr;

        } catch (err) {
            console.error(err);
            return false;
        }
    }
}


export default AlohomoraCTR;
