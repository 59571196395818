/*
**  Powered by WOPR Team's development
*/


import  React                                                   from "react";
import {Table, Container, Header, Icon, Segment, Button}        from 'semantic-ui-react'
import {Link}                                                   from "react-router-dom";
import ScenarioCTR                                              from  "../../../controllers/scenarios";
import { COLORS }                   from "../../../static/config/colors"


class ScenariosList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scenarios: [],
        }
    }


    componentDidMount() {
        this.getScenariosList();
        document.body.style.backgroundColor = COLORS.BACKGROUND_COLOR
    }


    async getScenariosList() {
        try {
            let res = await ScenarioCTR.list()
            await this.setState({
                scenarios: res,
            })
        } catch (err) {
            console.error(err);
        }
    }


    renderModules() {
        return this.state.scenarios.map((scenario) => {
            let route = "/scenarios/newEpic?scenario_id=" + scenario.id;

            return (
                <Table.Row key={scenario.id} textAlign='center' style={{ backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}}>
                    <Table.Cell>{scenario.name}</Table.Cell>
                    <Table.Cell>{scenario.modules}</Table.Cell>
                    <Table.Cell><Button style={{backgroundColor: COLORS.BUTTON_BACKGRND_COLOR, color: COLORS.BUTTON_TEXT_COLOR}} as={Link} to={route}>Create new epic</Button></Table.Cell>
                </Table.Row>
            )
        });
    }


    render() {
        return (
            <Container textAlign="center">
                <Segment style={{backgroundColor: COLORS.SEGMENTS_COLOR}}>
                    <Button style={{backgroundColor: COLORS.BUTTON_BACKGRND_COLOR, color: COLORS.BUTTON_TEXT_COLOR}} primary as={Link} to="/scenarios/new"> Create a new scenario </Button>
                </Segment>
                <Segment style={{backgroundColor: COLORS.SEGMENTS_COLOR}}>
                    <Header as='h2' icon>
                        <Icon name='code' />
                        Scenarios
                        <Header.Subheader>
                            Manage all your scenarios
                        </Header.Subheader>
                    </Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row textAlign='center'>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Modules</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            { this.renderModules() }
                        </Table.Body>
                    </Table>
                </Segment>
            </Container>
        )
    }
}


export default ScenariosList;
