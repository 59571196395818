/*
**  Powered by WOPR Team's development
*/


import  date      from "./date";

const utils = {
    ...date,
};


export default utils;
