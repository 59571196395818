/*
**  Powered by WOPR Team's development
*/


import  axios           from "axios"


class ScenariosCTR {

    static async create(data) {
        console.debug("CREATE SCENARIO");
        try {
            let request = await axios.create({ method: "post", baseURL: process.env.REACT_APP_API_BASE_ROUTE, headers: { "Authorization": "Token " + localStorage.getItem("auth_token")} });
            await request.post("/scenarios", data);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    static async list() {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.get("/scenarios", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
            return response.data.scenarios;
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    static async retrieve(id) {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.get("/scenarios/" + id, { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
            return response.data.scenario[0];
        } catch (err) {
            console.error(err);
            return null;
        }
    }


}


export default ScenariosCTR;
