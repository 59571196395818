/*
**  Powered by WOPR Team's development
*/


import  React                                                           from "react";
import {Container, Form, Grid, Header, Segment, Divider, Dropdown, Input} from 'semantic-ui-react'
import  DatePicker                                                      from 'react-datepicker'
import  MembersCTR                                                      from "../../../controllers/members";
import  autoBind                                                        from "auto-bind";
import  PentestsCTR                                                     from  "../../../controllers/pentests";
import "react-datepicker/dist/react-datepicker.css";
import "../../../static/css/wopr.css";


class NewPentest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pentest_name: null,
            date_start: null,
            date_end: null,
            members: [],
            member_options: [],
            new_target: "",
            target_list: [],
            target_options: [],
            kind_options: [],
            kind: "",
            error_popup_show: false,
            submit_button_status: "",
            submit_button_text: "Submit"
        };
        autoBind(this);
    }

    async componentDidMount() {
        try {
            // fill member_options, to be displayed in the dropdown menu
            let l_members_options = await MembersCTR.list();
            l_members_options = await l_members_options.map((member) => ({ key: member.id, text: member.username, value: member.username}));

            let l_kind_options = ['blackbox && graybox', 'blackbox', 'graybox', 'whitebox', 'internal', 'redteam'];
            l_kind_options = l_kind_options.map((kind) => ({key: kind, text: kind, value: kind}));

            await this.setState({
                member_options: l_members_options,
                kind_options: l_kind_options
            });
        } catch (err) {
            console.error(err);
        }
    }

    // add member_name to the members array
    async updateMembers(member_name){
        let new_member_array = this.state.members.slice();

        if (!this.state.members.includes(member_name)) {
            new_member_array.push(member_name);

            await this.setState({
                members: new_member_array,
            });
        }
    }

    async submit() {
        let is_manager = true;
        let formated_members = [];

        this.setState({
            submit_button_status : "disabled",
            submit_button_text: "Creating pentest, please wait..."
        })

        if (this.state.pentest_name === null ||
            this.state.pentest_name.length === 0 ||
            this.state.date_start === null ||
            this.state.date_end === null ||
            this.state.kind === null ||
            this.state.kind.length === 0 ||
            this.state.members.length === 0) {
                alert("Missing parameter.");
                this.setState({
                    submit_button_status : "",
                    submit_button_text: "Submit"
                })
                return;
            }

        if (this.state.target_list.length === 0){
            alert("Target list empty !");
            this.setState({
                submit_button_status : "",
                submit_button_text: "Submit"
            })
            return;
        }
        if (this.state.new_target.length > 0){
            alert("New target not added ! You need to click the \"+\" to add it to target list.");
            this.setState({
                submit_button_status : "",
                submit_button_text: "Submit"
            })
            return;
        }

        this.state.members.forEach((member_name) => {
            this.state.member_options.forEach((member_option) => {
                if (member_option.text === "Glados" || member_option.text === "wopr_super") {
                    formated_members.push({[member_option.key]: "consultant"});
                }
                else if (member_name === member_option.text){
                    if (is_manager) {
                        formated_members.push({[member_option.key]: "manager"});
                        is_manager = false;
                    }
                    else
                        formated_members.push({[member_option.key]: "consultant"});
                    console.log(formated_members);
                }
            });
        });

        let res = await PentestsCTR.create({
            name: this.state.pentest_name,
            date_start: this.state.date_start,
            date_end: this.state.date_end,
            kind: this.state.kind,
            hosts: this.state.target_list,
            members: formated_members,
        });
        if (res)
            this.props.history.push('/pentests');
        else
            this.setState({
                error_popup_show: true,
                submit_button_status : "",
                submit_button_text: "Submit"
            });
    }

    async handleChange(event, { name, value }){
        if (name === 'target_list' ||
         name === 'members' ||
         name === 'new_target' ||
         name === 'kind' ||
         name === 'pentest_name' ||
         name === 'date_start' ||
         name === 'date_end') {
            //console.log(name, value);
            await this.setState({ [name]: value });

            // uncomment this to debug
            /*if (name === 'pentest_name'){
            //    console.log("this.state.member_option : ", this.state.member_options);
            //    console.log("this.state.members : ", this.state.members);
            //    console.log("this.state.target_list : ", this.state.target_list);
            //    console.log("this.state.date_start : ", this.state.date_start);
            //    console.log("this.state.date_end : ", this.state.date_end);
            //}
            */
        }
    }


    // Add target to perimeter
    async addTarget() {
        if (this.state.new_target === "")
            return;
        let new_target_list = this.state.target_list.slice();
        new_target_list.push(this.state.new_target.replaceAll(" ", "").replaceAll(",", "."));

        // we must keep target_options properly filled up,
        // otherwise target list won't be displayed
        let new_target_options = this.state.target_options.slice();
        new_target_options.push({key: this.state.new_target, text: this.state.new_target, value: this.state.new_target})

        await this.setState({
            target_list: new_target_list,
            new_target: "",
            target_options: new_target_options
        })
        console.log("target_list", this.state.target_list);
    }

    render() {
        return (
            <Container>
            <Grid centered style={{ height: '100vh' }}>
                <Grid.Column style={{ maxWidth: 700, textAlign: 'center'}}>
                    <Divider hidden />
                    <Header as='h1' textAlign='center'>
                        Create a new Pentest
                    </Header>
                    <Form size='large'>
                        <Segment stacked>

                            {/* JOB NAME */}
                            <Form.Field
                                control={Input}
                                fluid
                                name='pentest_name'
                                label="Name"
                                placeholder='Give a name to your pentest'
                                onChange={this.handleChange}
                            />

                            {/* START DATE */}
                            <Form.Field
                                name='date_start'
                                label='Start Date'
                                control={DatePicker}
                                selected={this.state.date_start}
                                onChange={(d) => {this.handleChange(null, { name: 'date_start', value: d})}}
                                showTimeSelect
                                excludeTimes={[]}
                                dateFormat="d MMMM yyyy, h:mm aa"
                            />

                            {/* END DATE */}
                            <Form.Field
                                name='date_end'
                                label='End Date'
                                control={DatePicker}
                                selected={this.state.date_end}
                                onChange={(d) => {this.handleChange(null, { name: 'date_end', value: d})}}
                                showTimeSelect
                                excludeTimes={[]}
                                dateFormat="d MMMM yyyy, h:mm aa"
                            />

                            {/* KIND*/}
                                <label><b>Kind</b></label>
                            <Form.Field
                                    placeholder="Choose the kind of pentest"
                                    control={Dropdown}
                                    name='kind'
                                    fluid
                                    selection
                                    options={this.state.kind_options}
                                    onChange={ this.handleChange }
                                    value={this.state.kind}
                            />

                            {/* MEMBERS */}
                            <Form.Group grouped>
                                <label>Members</label>
                                <Form.Field
                                    placeholder="Select member(s) (First will will be manager)"
                                    control={Dropdown}
                                    name='members'
                                    fluid
                                    multiple
                                    selection
                                    options={this.state.member_options}
                                    onChange={ this.handleChange }
                                    value={this.state.members}
                                />
                            </Form.Group>

                            {/* PERIMETER */}
                            <Form.Group grouped>
                                <label>Perimeter</label>
                                <Form.Field
                                    name='new_target'
                                    control={Input}
                                    action={{ icon: 'add', onClick: this.addTarget }}
                                    fluid
                                    placeholder='Add an IP address or a domain'
                                    onChange={ this.handleChange }
                                    value={this.state.new_target}
                                />
                                <Form.Field
                                    control={Dropdown}
                                    name='target_list'
                                    fluid
                                    multiple
                                    selection
                                    options={this.state.target_options}
                                    placeholder=''
                                    onChange={ this.handleChange }
                                    value={this.state.target_list}
                                />
                            </Form.Group>

                            <Form.Button className={this.state.submit_button_status} onClick={this.submit}> {this.state.submit_button_text} </Form.Button>
                            <div>
                                {
                                this.state.error_popup_show?
                                    <p style={{color: "red"}}>Error : The form must be fully filled.</p>
                                    : null
                                }
                            </div>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
            </Container>
        )
    }

}


export default NewPentest;
