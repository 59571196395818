/*
**  Powered by WOPR Team's development
*/


import  React, { Component }                from 'react'
import  { Divider }                         from 'semantic-ui-react'
import  PentestsList                        from "./PentestsList";
import  NewPentest                          from "./NewPentest";
import  NavBarLayout                        from "../../layouts/NavBarLayout"
import  { switchTab }                       from "../../../store/actions/routing";
import  { connect }                         from "react-redux"
import	{ Switch, Route }                   from "react-router-dom";
import ShowPentest                          from './ShowPentest';


class Pentests extends Component {

    componentDidMount() {
        this.props.switchTab('pentests');
        console.log(this.props)
        this.props.history.push(this.props.location);
    }

    render() {
        return (
            <NavBarLayout>
                <Divider hidden />
                <Switch>
                    <Route exact path="/pentests" component={PentestsList} />
                    <Route exact path="/pentests/new" component={NewPentest} />
                    <Route path="/pentests/:id" component={ShowPentest} />
                </Switch>
                <Divider hidden />
            </NavBarLayout>
        )
    }
}


export default connect((state) => { return { routing: state.routing }}, { switchTab })(Pentests);
