/*
**  Powered by WOPR Team's development
*/

import "react-datepicker/dist/react-datepicker.css";
import "../../../static/css/wopr.css";
import  React                                                           from "react";
import {Container, Form, Grid, Header, Segment, Divider, Dropdown, Input} from 'semantic-ui-react'
import  PentestCTR                                                      from "../../../controllers/pentests";
import  HostCTR                                                         from "../../../controllers/hosts";
import  autoBind                                                        from "auto-bind";
import  ScenarioCTR                                                     from  "../../../controllers/scenarios";
import  qs                                                              from "query-string"
import EpicsCTR from "../../../controllers/epics";


class NewEpic extends React.Component {

    constructor(props) {
        super(props);

        let scenario_id = qs.parse(props.location.search).scenario_id;
        this.state = {
            name: null,
            scenario_id: scenario_id,
            scenario: {},
            pentest_options: [],
            pentest_selected: "",
            pentest_id_by_name: {},
            new_target: "",
            ip_list_options: [],
            ip_list: [],
            domain_list_options: [],
            domain_list: [],
        };
        autoBind(this);
    }

    async componentDidMount() {
        try {
            let l_scenario = await ScenarioCTR.retrieve(this.state.scenario_id)
            let l_pentest_list = await PentestCTR.list()
            l_pentest_list = await l_pentest_list.map((l_pentest) => ({ key: l_pentest.id, text: l_pentest.name, value: l_pentest.name }));

            // here we fill a dit so we can retrieve easily a pentest ID with its name
            let l_pentest_id_by_name = {}
            for (let i = 0; i < l_pentest_list.length; i++) {
                l_pentest_id_by_name[l_pentest_list[i].text]= l_pentest_list[i].key
            }
            console.log("l_pentest_id_by_name")
            console.log(l_pentest_id_by_name)
            await this.setState({
                scenario: l_scenario,
                pentest_options: l_pentest_list,
                pentest_id_by_name: l_pentest_id_by_name
            });
        } catch (err) {
            console.error(err);
        }
    }

    async submit() {
        console.log("submit")

        let epic_ip_string = "["
        if (this.state.ip_list !== undefined) {
            for (let i = 0; i < this.state.ip_list.length; i++) {
                epic_ip_string = epic_ip_string + "\"" + this.state.ip_list[i] + "\""
            }
        }
        epic_ip_string = epic_ip_string + "]"

        let epic_domain_string = "["
        if (this.state.domain_list !== undefined) {
            for (let i = 0; i < this.state.domain_list.length; i++) {
                epic_domain_string = epic_domain_string + "\"" + this.state.domain_list[i] + "\""
            }
        }
        epic_domain_string = epic_domain_string + "]"

        console.log(epic_domain_string)
        console.log(epic_ip_string)

        await EpicsCTR.create({
            name: this.state.name,
            scenario_id: this.state.scenario_id,
            pentest_id: this.state.pentest_id_by_name[this.state.pentest_selected],
            status: ".",
            epic_domain_list: epic_domain_string,
            epic_ip_list: epic_ip_string,
            related_jobs: "None",

        })

        this.props.history.push('/epics')
    }

    async addTargetIP(new_ip) {
        let l_new_ip_list = this.state.ip_list.slice()
        let l_new_ip_list_option = this.state.ip_list_options.slice()

        l_new_ip_list.push(new_ip)
        l_new_ip_list_option.push({ key: new_ip, text: new_ip, value: new_ip })

        await this.setState({
            ip_list_options: l_new_ip_list_option,
            ip_list: l_new_ip_list,
        });
    }

    async addTargetDomain(new_domain) {
        let l_new_domain_list = this.state.domain_list.slice()
        let l_new_domain_list_option = this.state.domain_list_options.slice()

        l_new_domain_list_option.push({ key: new_domain, text: new_domain, value: new_domain })
        await this.setState({
            domain_list_options: l_new_domain_list_option,
            domain_list: l_new_domain_list,
        });
    }

    async handleChange(event, { name, value }) {
        await this.setState({ [name]: value });

        if (name === "pentest_selected") {
            await this.setState({
                domain_list_options: [],
                domain_list: [],
                ip_list_options: [],
                ip_list: [],
            });

            let targets = await HostCTR.listForPentest(this.state.pentest_id_by_name[this.state.pentest_selected])

            for (let i = 0; i < targets.length; i++) {
                if (targets[i].kind === "ip") {
                    this.addTargetIP(targets[i].hostname)
                }
                else if (targets[i].kind === "domain") {
                    this.addTargetDomain(targets[i].hostname)
                }
            }
        }
    }

    async addTarget() {
        if(this.isIP(this.state.new_target)) {
            if (!this.state.ip_list.includes(this.state.new_target))
                this.addTargetIP(this.state.new_target)
        }
        else if (!this.state.domain_list.includes(this.state.new_target))
            this.addTargetDomain(this.state.new_target)
        await this.setState({
            new_target: ""
        });
        console.log(this.state.domain_list)
        console.log(this.state.ip_list)

    }

    isIP(str) {
        const regex = new RegExp('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')
        if (regex.test(str))
            return true;
        return false;
    }

    render() {
        return (
            <Container>
            <Grid centered style={{ height: '100vh' }}>
                <Grid.Column style={{ maxWidth: 700, textAlign: 'center'}}>
                    <Divider hidden />
                    <Header as='h1' textAlign='center'>
                        Create a new epic
                    </Header>
                    <h2>Scenario : {this.state.scenario.name}</h2>
                    <Form size='large'>
                        <Segment stacked>

                            {/* EPIC NAME */}
                            <Form.Field
                                label="Epic name"
                                control={Input}
                                fluid
                                name='name'
                                placeholder='Give a name to your epic'
                                onChange={this.handleChange}
                            />

                            <Form.Field
                                label="Pentest"
                                placeholder="Choose a pentest"
                                control={Dropdown}
                                name='pentest_selected'
                                fluid
                                selection
                                options={this.state.pentest_options}
                                onChange={ this.handleChange }
                                value={this.state.pentest_selected}
                            />

                            <Form.Group grouped>
                            <label><h3><br/>Targets</h3></label>
                            <Form.Field
                                    name='new_target'
                                    control={Input}
                                    action={{ icon: 'add', onClick: this.addTarget }}
                                    fluid
                                    placeholder='Add an IP address or a domain'
                                    onChange={ this.handleChange }
                                    value={this.state.new_target}
                                />
                                <label>Target IP</label>
                                <Form.Field
                                    control={Dropdown}
                                    name='ip_list'
                                    fluid
                                    multiple
                                    selection
                                    options={this.state.ip_list_options}
                                    placeholder=''
                                    onChange={ this.handleChange }
                                    value={this.state.ip_list}
                                />
                                <label>Target domains</label>
                                <Form.Field
                                    control={Dropdown}
                                    name='domain_list'
                                    fluid
                                    multiple
                                    selection
                                    options={this.state.domain_list_options}
                                    placeholder=''
                                    onChange={ this.handleChange }
                                    value={this.state.domain_list}
                                />
                            </Form.Group>

                            <Form.Button
                                onClick={this.submit}>Epic launch
                            </Form.Button>
                            <div>
                                {
                                this.state.error_popup_show?
                                    <p style={{color: "red"}}>Error : The form must be fully filled.</p>
                                    : null
                                }
                            </div>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
            </Container>
        )
    }

}

export default NewEpic;
