/*
**  Powered by WOPR Team's development
*/


import  axios           from "axios"

class NessusCTR {

    static async retrieve(scan_id, pentest_name) {
        try {
            axios({
                url: process.env.REACT_APP_API_BASE_ROUTE + "/nessus/" + scan_id,
                headers: { "Authorization": "Token " + localStorage.getItem("auth_token")},
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'nessu_report_' + pentest_name + '.pdf');
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });

        } catch (err) {
            console.error(err);
            return 400;
        }
    }
}

export default NessusCTR;
