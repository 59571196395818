/*
**  Powered by WOPR Team's development
*/


import  axios                                                       from 'axios';
import  React                                                       from 'react';
import  { connect }                                                 from 'react-redux';
import  { Button, Form, Grid, Header, Icon, Message, Segment }      from 'semantic-ui-react'
import  { signin, signout, checkAuth }                              from '../../store/actions/auth';
import  autoBind                                                    from "react-autobind";


class Login extends React.Component {

    state = {
        username: null,
        password: null,
        login_error: false,
    };


    constructor(props) {
        super(props);
        autoBind(this);
    }


    async componentDidMount() {
        await this.props.checkAuth();
        if (this.props.auth.isAuthenticated) {
            this.props.history.push(this.props.routing.redirected_path);
        }
    }


    async login() {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.post("/login", {
                username: this.state.username,
                password: this.state.password,
            });
            this.props.signin(response.data.token);
            this.props.history.push('/');
            return true;
        } catch (err) {
            console.error(err);
            this.props.signout();
            await this.setState({ login_error: true });
            return false;
        }
    }


    async handleChange(event) {
        let state ={};
        state[event.target.name] = event.target.value;
        await this.setState(state);
    }


    render() {
        let msg_error = null;
        if (this.state.login_error)
            msg_error = <Message color="red" negative> Username / Password incorrect </Message> ;

        return (
            <Grid textAlign='center' style={{ height: '100vh',  backgroundImage: `url(${"https://img.wallpapersafari.com/desktop/1920/1080/73/66/NZH4Vg.jpg"})`, }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h1' color='grey' textAlign='center'>
                        <Icon name="angle right"/> Log-in to your account
                    </Header>
                    {msg_error}
                    <Form size='large'>
                        <Segment stacked>
                            <Form.Input name="username" fluid icon='user' iconPosition='left' placeholder='E-mail address' onChange={this.handleChange.bind(this)} />
                            <Form.Input name="password" fluid icon='lock' iconPosition='left' placeholder='Password' type='password' onChange={this.handleChange.bind(this)} />
                            <Button color='blue' fluid size='large' onClick={this.login}>
                                Login
                            </Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}


export default connect(
    (state) => { return { auth: state.auth, routing: state.routing }},
    { signin, signout, checkAuth },
)(Login);
