/*
**  Powered by WOPR Team's development
*/


import  axios           from "axios"


class MembersCTR {

    static async list() {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.get("/users", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});

            return response.data.messages;
        } catch (err) {
            console.error(err);
            return [];
        }
    }

}

export default MembersCTR;
