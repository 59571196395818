/*
**  Powered by WOPR Team's development
*/


function formatDate(gmt_date) {
    let date = new Date(gmt_date);
    date = date.toLocaleTimeString() + ' ' + date.toLocaleDateString();
    return date;
}


function compareDate(dateone, datetwo) {
    return dateone < datetwo;
}

export default { formatDate, compareDate };