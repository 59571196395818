/*
**  Powered by WOPR Team's development
*/


import  React, { Component }                from 'react'
import  { Modal, Button }                   from 'semantic-ui-react'


class EpicOutputModal extends Component {

    constructor(props) {
        super(props);
        this.state = { output: null };
    }


    async componentDidMount() {
        await this.setState({ output: this.props.job_data });
    }


    render() {
        return (
            <Modal trigger={<Button>Show Output</Button>}>
                <Modal.Header>Output</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <code>
                            {this.state.output}
                        </code>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }

}


export default EpicOutputModal;
