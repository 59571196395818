/*
**  Powered by WOPR Team's development
*/


import  React                                                   from 'react'
import {Table, Form, Segment, Dropdown, Input, Checkbox, Header }       from 'semantic-ui-react'
import  axios                                                   from "axios"
import  autoBind                                                from "auto-bind"
import { COLORS }                                               from "../../../static/config/colors"
import { InputFile }                                            from 'semantic-ui-react-input-file'

class OracklePage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            running_sessions: [],
            deadline_option: [{key: 1, text: "1 hour", value: 3600},
                              {key: 2, text: "7 hours", value: 25200},
                              {key: 3, text: "2 days", value: 172800},
                              {key: 4, text: "7 days", value: 604800},
                              {key: 5, text: "15 days", value: 1296000},
                              {key: 6, text: "30 days", value: 2592000},
                              {key: 7, text: "infinity", value: 31536000},],
            auditor_name : "",
            client_name: "",
            format_option: [],
            hashfile_option: [],
            hashfile_selected: "",
            hashfile_selected_stats: "",
            hashfile_format_selected: "",
            hashfile_format_selected_stats: "",
            hashfile: "",
            hashfile_name: "",
            customword: "",
            customword_name : "",
            format: "",
            deadline: "",
            stats: false,
            force_john: false,
            start_session_status: "disabled",
            delete_hashfile_status: "disabled",
            delete_wordlist_status: "disabled",
            get_session_result_name: "",
            display_orackle_api_error: false,
            orackle_api_error_text: ""
        }

        autoBind(this)
    }


    async componentDidMount() {
        this.update_sessions()
        await this.getFormatList();
        await this.getHashfileList();
        setTimeout(this.update_sessions, 10000)
        document.body.style.backgroundColor = COLORS.BACKGROUND_COLOR
    }

    async getHashfileList() {
        let request = await axios.create({baseURL: process.env.REACT_APP_ORACKLE_ROUTE})
        let hashfiles = await request.get("/hashfile")

        var temp_array = []
        var temp_key = 1
        for (var val in hashfiles.data) {
            temp_array.push({key:temp_key, text:hashfiles.data[val], value:hashfiles.data[val]})
            temp_key = temp_key + 1
        }
        console.log(temp_array)
        this.setState({
            hashfile_option : temp_array
        })
    }

    async getFormatList() {
        let request = await axios.create({baseURL: process.env.REACT_APP_ORACKLE_ROUTE})
        let format_options = await request.get("/format")

        var reversed_key_val_array = {}

        if (format_options['status'] !== 200) {
            this.setState({
                orackle_api_error_text : "code : " + format_options['status'] + " => " + format_options['statusText'],
                display_orackle_api_error: true
            })
            return
        }

        for (var key in format_options.data) {
            reversed_key_val_array[format_options.data[key]] = parseInt(key)
        }

        var sortedKeys = Object.keys(reversed_key_val_array).sort();

        var temp_array = []
        var temp_key = 1

        for (var val in sortedKeys) {
            temp_array.push({key:temp_key, text:format_options.data[reversed_key_val_array[sortedKeys[val]]], value:reversed_key_val_array[sortedKeys[val]]})
            temp_key = temp_key + 1
        }

        this.setState({
            format_option : temp_array
        })
    }

    async enableStartSessionButton(){
        if (this.state.auditor_name !== "" &&
            this.state.client_name !== "" &&
            this.state.hashfile !== "" &&
            this.state.customword !== "" &&
            this.state.format !== "" &&
            this.state.deadline !== "") {
            await this.setState({
                start_session_status: "enabled"
            })
            //console.log("yep")
        }
        else {
            await this.setState({
                start_session_status: "disabled"
            })
            //console.log("nope")
        }
    }

    async update_sessions() {
        let request = await axios.create({baseURL: process.env.REACT_APP_ORACKLE_ROUTE})
        let running_sessions = await request.get("/crack")
        let sessions_array = []
        console.log("running_sessions")
        console.log(running_sessions.data)

        for (const session of running_sessions.data) {
            sessions_array.push(session)
            console.log("session")
            console.log(session)
        }
        await this.setState({
            running_sessions: sessions_array
        })
    }

    async handleChange(event, { name, value }){
        await this.setState({ [name]: value });
        console.log("") // this line is necessary otherwise the next function is never executed.... JS crap
        this.enableStartSessionButton()
    }

    async kill_session() {
        console.log(this.state.session_to_kill_name)
        let url = "/crack?session=" + this.state.session_to_kill_name
        await this.setState({
            session_to_kill_name: ""
        })
        let request = await axios.create({baseURL: process.env.REACT_APP_ORACKLE_ROUTE})
        await request.delete(url)
        this.update_sessions()
    }

    async get_session_results() {
        if (this.state.hashfile_selected === "" || this.state.hashfile_format_selected === "")
            return
        var temp_result = {}
        let url = await axios.create({baseURL: process.env.REACT_APP_ORACKLE_ROUTE})
        let request = "/crack/john?hashfile=" + this.state.hashfile_selected + "&format=" + this.state.hashfile_format_selected
        var response_array = await url.get(request)

        temp_result["john"] = response_array.data
        request = "/crack/hashcat?hashfile=" + this.state.hashfile_selected + "&format=" + this.state.hashfile_format_selected
        response_array = await url.get(request)
        temp_result["hashcat"] = response_array.data

        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(temp_result).replaceAll('\\\"', "\"").slice(1,-1)], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = this.state.hashfile_selected + "_result.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        await this.setState({
            hashfile_selected: "",
            hashfile_format_selected: ""
        })
    }

    async get_statistics() {
        if (this.state.hashfile_format_selected_stats === "" || this.state.hashfile_selected_stats === "")
            return
        var temp_result = {}
        let url = await axios.create({baseURL: process.env.REACT_APP_ORACKLE_ROUTE})
        let request = "/stats?techno=john&filename=" + this.state.hashfile_selected_stats + "&format=" + this.state.hashfile_format_selected_stats
        var response_array = await url.get(request)

        temp_result["john"] = response_array.data
        request = "/stats?techno=hashcat&filename=" + this.state.hashfile_selected_stats + "&format=" + this.state.hashfile_format_selected_stats
        response_array = await url.get(request)
        temp_result["hashcat"] = response_array.data

        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(temp_result).replaceAll('\\\"', "\"").slice(1,-1)], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = this.state.hashfile_selected + "_statistics.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        await this.setState({
            hashfile_format_selected_stats: "",
            hashfile_selected_stats: ""
        })
    }

    async delete_hashfile() {
        await this.setState({
            hashfile: "",
            hashfile_name: "",
            delete_hashfile_status: "disabled"
        })
        this.enableStartSessionButton()
    }
    async delete_wordlist() {
        await this.setState({
            customword: "",
            customword_name: "",
            delete_wordlist_status: "disabled"
        })
    }

    async start_crack() {
        let request = await axios.create({
            baseURL: process.env.REACT_APP_ORACKLE_ROUTE,
            })
        delete request.defaults.headers.common['Access-Control-Request-Headers'];

        try {
        var result = await request.post("/crack_wopr", {
            auditor_name : this.state.auditor_name,
            client_name: this.state.client_name,
            hashfile: Buffer.from(this.state.hashfile).toString('base64'),
            hashfile_name: this.state.hashfile_name,
            customword: Buffer.from(this.state.customword).toString('base64'),
            customword_name: this.state.customword_name,
            format: this.state.format.toString(),
            deadline: this.state.deadline,
            stats: this.state.stats,
            force_john: this.state.force_john
            })
        }
        catch(error) {
            if (error.response.status !== 200) {

                await this.setState({
                    orackle_api_error_text : "code : " + error.response.status.toString() + " => " + error.response.data['message'],
                    display_orackle_api_error: true
                })
            }
        }
    }

    renderRunningSessions() {

        return this.state.running_sessions.map((session) => {
            return (
                <Table.Row key={session} textAlign='center'>
                    <Table.Cell>{session.session_name}</Table.Cell>
                    <Table.Cell>{session.filename}</Table.Cell>
                    <Table.Cell>{session.format}</Table.Cell>
                    <Table.Cell>Not implemented yet</Table.Cell>
                </Table.Row>
            )
        });
    }

    async handleUploadHashFile(event) {
        const reader = new FileReader();
        reader.addEventListener(
            "load",
            () => {
                this.setState({
                    hashfile : reader.result
                })
            },
            false
          );
        reader.readAsText(event.target.files[0])
        await this.setState({
            hashfile_name: event.target.files[0].name,
            delete_hashfile_status: 'enabled'
        })

    }

    async handleUploadCustomWordlist(event) {
        const reader = new FileReader();
        reader.addEventListener(
            "load",
            () => {
                this.setState({
                    customword : reader.result
                })
            },
            false
          );
        reader.readAsText(event.target.files[0])
        await this.setState({
            customword_name: event.target.files[0].name,
            delete_wordlist_status: 'enabled'
        })

    }

    async checkStats(event) {
        await this.setState({
            stats: !this.state.stats
        })
    }

    async checkForceJohn(event) {
        await this.setState({
            force_john: !this.state.force_john
        })
    }

    render() {
        return (
            <div className="global_container_orackle">
                <div className="container_orackle">
                    <div className="left">
                        <Segment className="individual_container_orackle" style={{backgroundColor: COLORS.SEGMENTS_COLOR}}>
                            <h1>Running sessions</h1>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row className="table_header" style={{ backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}}>
                                        <Table.HeaderCell>Session name</Table.HeaderCell>
                                        <Table.HeaderCell>File neme</Table.HeaderCell>
                                        <Table.HeaderCell>Format</Table.HeaderCell>
                                        <Table.HeaderCell>Broken hashes</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {this.renderRunningSessions()}
                                </Table.Body>
                            </Table>
                        </Segment>
                        <Segment style={{backgroundColor: COLORS.SEGMENTS_COLOR}} className="individual_container_orackle" >
                            <h1>Kill session</h1>
                            <Form size="large">
                                <Form.Field
                                    control={Input}
                                    fluid
                                    name='session_to_kill_name'
                                    placeholder='Session name to kill'
                                    onChange={this.handleChange}
                                    value={this.state.session_to_kill_name}
                                />
                                <Form.Button
                                    style={{backgroundColor: COLORS.RED_NEGATIVE, color: COLORS.RED_NEGATIVE_TEXT}}
                                    className="button" onClick={this.kill_session}><i className="large trash alternate icon"></i>Kill session
                                </Form.Button>
                            </Form>
                        </Segment>
                        <Segment style={{backgroundColor: COLORS.SEGMENTS_COLOR}} className="individual_container_orackle" >
                            <h1>Get result</h1>
                            <Form size="large">
                                <Form.Field
                                    control={Dropdown}
                                    fluid
                                    selection
                                    options={this.state.hashfile_option}
                                    name='hashfile_selected'
                                    placeholder='Select a file'
                                    onChange={this.handleChange}
                                    value={this.state.hashfile_selected}
                                />
                                <Form.Field
                                    control={Dropdown}
                                    fluid
                                    selection
                                    options={this.state.format_option}
                                    name='hashfile_format_selected'
                                    placeholder='Select a format'
                                    onChange={this.handleChange}
                                    value={this.state.hashfile_format_selected}
                                />
                                <Form.Button
                                    style={{backgroundColor: COLORS.BLUE_NEUTRAL, color: COLORS.BLUE_NEUTRAL_TEXT}}
                                    className="button" onClick={this.get_session_results}><i className="large check alternate icon"></i>Get result
                                </Form.Button>
                            </Form>
                        </Segment>
                        <Segment style={{backgroundColor: COLORS.SEGMENTS_COLOR}} className="individual_container_orackle">
                            <h1>Statistics</h1>
                            <Form size="large">
                                <Form.Field
                                    control={Dropdown}
                                    fluid
                                    selection
                                    options={this.state.hashfile_option}
                                    name='hashfile_selected_stats'
                                    placeholder='Select a file'
                                    onChange={this.handleChange}
                                    value={this.state.hashfile_selected_stats}
                                />
                                <Form.Field
                                    control={Dropdown}
                                    fluid
                                    selection
                                    options={this.state.format_option}
                                    name='hashfile_format_selected_stats'
                                    placeholder='Select a format'
                                    onChange={this.handleChange}
                                    value={this.state.hashfile_format_selected_stats}
                                />
                                <Form.Button
                                    style={{backgroundColor: COLORS.BLUE_NEUTRAL, color: COLORS.BLUE_NEUTRAL_TEXT}}
                                    className="button" onClick={this.get_session_results}><i className="large check alternate icon"></i>Get statistics
                                </Form.Button>
                            </Form>
                        </Segment>
                    </div>
                    <div className="right">
                        <Segment className="individual_container_orackle" style={{backgroundColor: COLORS.SEGMENTS_COLOR}}>
                            <h1>Start session</h1>
                            {this.state.display_orackle_api_error &&
                                <Header as='h2' color='red'>
                                    API request FAILED !<br/>
                                    {this.state.orackle_api_error_text}
                                </Header>
                            }

                            <Form size="large">
                                <Form.Field
                                    control={Input}
                                    fluid
                                    name='auditor_name'
                                    placeholder='Auditor name'
                                    onChange={this.handleChange}
                                    value={this.state.auditor_name}
                                />
                                <Form.Field
                                    control={Input}
                                    fluid
                                    name='client_name'
                                    placeholder='Client name'
                                    onChange={this.handleChange}
                                    value={this.state.client_name}
                                />
                                <Form.Field
                                    control={Dropdown}
                                    fluid
                                    selection
                                    options={this.state.format_option}
                                    name='format'
                                    placeholder='Select a format'
                                    onChange={this.handleChange}
                                    value={this.state.format}
                                />
                                <h3>Hash file : {this.state.hashfile_name}</h3>
                                <div className='delete_file_div'>
                                    <InputFile
                                        button={{  }}
                                        input={{
                                            id: 'upload_hashfile',
                                            onChange: this.handleUploadHashFile
                                        }}
                                    />
                                    <Form.Button
                                            style={{backgroundColor: COLORS.RED_NEGATIVE, color: COLORS.RED_NEGATIVE_TEXT}}
                                            className={this.state.delete_hashfile_status}
                                            onClick={this.delete_hashfile}><i className="large trash alternate icon"></i>Delete file
                                    </Form.Button>
                                </div>
                                <h3>Custom wordlist file : {this.state.customword_name}</h3>
                                <div className='delete_file_div'>
                                    <InputFile
                                        className='select_file_button'
                                        button={{  }}
                                        input={{
                                            id: 'customword',
                                            onChange: this.handleUploadCustomWordlist
                                        }}
                                    />
                                    <Form.Button
                                        style={{backgroundColor: COLORS.RED_NEGATIVE, color: COLORS.RED_NEGATIVE_TEXT}}
                                        className={this.state.delete_wordlist_status}
                                        onClick={this.delete_wordlist}><i className="large trash alternate icon"></i>Delete file
                                    </Form.Button>

                                </div>
                                <h3>Deadline</h3>
                                <Form.Field
                                    control={Dropdown}
                                    name='deadline'
                                    fluid
                                    selection
                                    options={this.state.deadline_option}
                                    placeholder='Select a deadline'
                                    onChange={ this.handleChange }
                                    value={this.state.deadline}
                                />
                                <Form.Field>
                                    <Checkbox toggle label='Force use of John' onClick={this.checkForceJohn} />
                                </Form.Field>
                                <Form.Field>
                                    <Checkbox toggle label='Generate statistics' onClick={this.checkStats} />
                                </Form.Field>

                                <Form.Button
                                    style={{backgroundColor: COLORS.GREEN_POSITIVE, color: COLORS.GREEN_POSITIVE_TEXT}}
                                    className={this.state.start_session_status}
                                    onClick={this.start_crack}><i className="large check alternate icon"></i>Start session
                                </Form.Button>
                            </Form>

                        </Segment>
                    </div>
                </div>
            </div>
            )
    }
}


export default OracklePage