/*
**  Powered by WOPR Team's development
*/


import  axios                                                       from "axios"
import  React                                                       from "react";
import {Table, Container, Header, Icon, Segment, Button} from 'semantic-ui-react'
import {Link} from "react-router-dom";


class ModulesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modules: [],
        }
    }


    componentDidMount() {
        this.getModulesList();
    }


    async getModulesList() {
        try {
            let request = await axios.create({baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.get("/modules", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
            await this.setState({
                modules: response.data.modules,
            });
            return response.data.modules;
        } catch (err) {
            console.error(err);
            return [];
        }
    }


    renderModules() {
        return this.state.modules.map((module) => {
            let route = "/jobs/new?module_id=" + module.id;

            return (
                <Table.Row key={module.id} textAlign='center'>
                    <Table.Cell>{module.name}</Table.Cell>
                    <Table.Cell>{module.author}</Table.Cell>
                    <Table.Cell>{module.scope}</Table.Cell>
                    <Table.Cell><Button as={Link} to={route}> Create </Button></Table.Cell>
                </Table.Row>
            )
        });
    }


    render() {
        return (
            <Container textAlign="center">
                    <Segment>
                        <Button primary as={Link} to="/modules/new"> Upload new module </Button>
                    </Segment><Segment>
                    <Header as='h2' icon>
                        <Icon name='code' />
                        Modules
                        <Header.Subheader>
                            Manage all your modules
                        </Header.Subheader>
                    </Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row textAlign='center'>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Author</Table.HeaderCell>
                                <Table.HeaderCell>Scope</Table.HeaderCell>
                                <Table.HeaderCell>Create job</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            { this.renderModules() }
                        </Table.Body>
                    </Table>
                </Segment>
            </Container>
        )
    }
}


export default ModulesList;
