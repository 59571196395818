/*
**  Powered by WOPR Team's development
*/


import  { combineReducers }     from 'redux';
import  auth                    from './auth';
import  routing                 from './routing';


const reducers = combineReducers({
    auth,
    routing,
});


export default reducers;
