/*
**  Powered by WOPR Team's development
*/


import  React                                                                       from "react";
import  { Component }                                                               from "react";
import  { Link }                                                                    from "react-router-dom";
import  { Responsive, Visibility, Segment, Menu, Container, Sidebar, Icon, Button } from "semantic-ui-react"
import  { connect }                                                                 from "react-redux";
import  { checkAuth, signout }                                                      from "../../store/actions/auth";
import  { switchTab }                                                               from "../../store/actions/routing";


const getWidth = () => {
    const isSSR = typeof window === 'undefined';
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
};

class DesktopNavBar extends Component {
    state = {
        active: "home"
    };

    hideFixedMenu = () => this.setState({ fixed: false });
    showFixedMenu = () => this.setState({ fixed: true });

    handleClick = (e, { name }) => {
        this.props.switchTab(name);
    };

    renderLoginButton() {
        if (!this.props.auth.isAuthenticated)
            return <Button as={Link} to='/login' inverted={!this.state.fixed}>Log in</Button>;
        else
            return <Button as={Link} to='/login' inverted={!this.state.fixed} onClick={async () => await this.props.signout() }>Log out</Button>;
    }

    render() {
        const { children, header, routing } = this.props;
        const { fixed } = this.state;
        let minHeight = header ? 700 : 20;

        return (
            <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
                <Visibility
                    once={false}
                    onBottomPassed={this.showFixedMenu}
                    onBottomPassedReverse={this.hideFixedMenu}
                >
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ minHeight: minHeight, padding: '1em 0em',  backgroundImage: 'url(https://img.wallpapersafari.com/desktop/1920/1080/73/66/NZH4Vg.jpg)' }}
                        vertical
                    >
                        <Menu
                            fixed={fixed ? 'top' : null}
                            inverted={!fixed}
                            pointing={!fixed}
                            secondary={!fixed}
                            size='large'
                        >
                            <Container>

                                <Menu.Item name="home" as={Link} to="/home" onClick={this.handleClick} active={routing.active_tab === 'home'}> Home </Menu.Item>
                                <Menu.Item name="pentests" as={Link} to="/pentests" onClick={this.handleClick} active={routing.active_tab === 'pentests'}> Pentests </Menu.Item>
                                <Menu.Item name="scenarios" as={Link} to="/scenarios" onClick={this.handleClick} active={routing.active_tab === 'scenarios'}> Scenarios </Menu.Item>
                                <Menu.Item name="orackle" as={Link} to="/orackle" onClick={this.handleClick} active={routing.active_tab === 'orackle'}> Orackle </Menu.Item>
                                <Menu.Item name="alohomora" as={Link} to="/alohomora" onClick={this.handleClick} active={routing.active_tab === 'alohomora'}> Alohomora </Menu.Item>

                                <Menu.Item position='right'>
                                    { this.renderLoginButton.bind(this)() }
                                </Menu.Item>
                            </Container>
                        </Menu>
                        { header }
                    </Segment>
                </Visibility>

                {children}

            </Responsive>
        )
    }
}

const CDesktopNavBar = connect((state) => { return { auth: state.auth, routing: state.routing }}, { signout, switchTab })(DesktopNavBar);


class MobileNavBar extends Component {
    state = {};

    handleSidebarHide = () => this.setState({ sidebarOpened: false });
    handleToggle = () => this.setState({ sidebarOpened: true });

    renderLoginButton() {
        if (!this.props.auth.isAuthenticated)
            return <Button as={Link} to='/login' inverted={!this.state.fixed}>Log in</Button>;
        else
            return <Button as={Link} to='/login' inverted={!this.state.fixed} onClick={async () => await this.props.signout() }>Log out</Button>;
    }

    render() {
        const { children } = this.props;
        let header = this.props.header;
        if (header)
            header = React.cloneElement(this.props.header, { mobile: true });
        const { sidebarOpened } = this.state;
        let minHeight = header ? 350 : 10;

        return (
            <Responsive
                as={Sidebar.Pushable}
                getWidth={getWidth}
                maxWidth={Responsive.onlyMobile.maxWidth}
            >
                <Sidebar
                    as={Menu}
                    animation='push'
                    inverted
                    onHide={this.handleSidebarHide}
                    vertical
                    visible={sidebarOpened}
                >
                    <Menu.Item as='a' active> Home </Menu.Item>
                    <Menu.Item as='a'>Pentests</Menu.Item>
                    <Menu.Item as='a'>Modules</Menu.Item>
                    <Menu.Item as='a'>Profile</Menu.Item>




                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ minHeight: minHeight, padding: '1em 0em'}}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size='large'>
                                <Menu.Item onClick={this.handleToggle}>
                                    <Icon name='sidebar' />
                                </Menu.Item>

                                <Menu.Item position='right'>
                                    { this.renderLoginButton.bind(this)() }
                                </Menu.Item>

                            </Menu>
                        </Container>
                        { header }
                    </Segment>
                    { children }
                </Sidebar.Pusher>
            </Responsive>
        )
    }
}

const CMobileNavBar = connect((state) => { return { auth: state.auth }}, { signout })(MobileNavBar);


class NavBarLayout extends React.Component {

    async componentDidMount() {
        await this.props.checkAuth();
    }

    render() {
        const { header, children } = this.props;

        return (
            <div>
                <CDesktopNavBar header={header}> { children } </CDesktopNavBar>
                <CMobileNavBar header={header}> { children } </CMobileNavBar>
            </div>
        );
    }
}


export default connect(null, { checkAuth })(NavBarLayout);
