/*
**  Powered by WOPR Team's development
*/


import  React, { Component }                from 'react'
import  { Divider }                         from 'semantic-ui-react'
import  NavBarLayout                        from "../../layouts/NavBarLayout"
import  { switchTab }                       from "../../../store/actions/routing";
import  { connect }                         from "react-redux"
import	{ Switch, Route }                   from "react-router-dom";
import  NewScenario                         from "./NewScenario";
import  ScenariosList                       from "./ScenariosList";
import NewEpic                              from '../epics/NewEpic';


class Scenario extends Component {

    componentDidMount() {
        this.props.switchTab('scenarios');
        this.props.history.push('/scenarios');
    }


    // <Route path="/scenarios/newEpic" component={NewEpic} />
    // we manage the new epic creation here in the scenarios instead of doing it in epics
    // otherwise we end up with a weird behaviors between changing page and opening the modal window

    render() {
        return (
            <NavBarLayout>
                <Divider hidden />
                <Switch>
                    <Route exact path="/scenarios" component={ScenariosList} />
                    <Route path="/scenarios/new" component={NewScenario} />
                    <Route path="/scenarios/newEpic" component={NewEpic} />
                </Switch>
                <Divider hidden />
            </NavBarLayout>
        )
    }
}


export default connect((state) => { return { routing: state.routing }}, { switchTab })(Scenario);
