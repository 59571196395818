/*
**  Powered by WOPR Team's development
*/


import	React			from 'react';
import	ReactDOM		from 'react-dom';
import	Root			from "./components/routing/Root"
import	'semantic-ui-css/semantic.min.css'
import * as dotenv from 'dotenv'

dotenv.config()

ReactDOM.render(
	<Root/>,
	document.getElementById('root')
);
