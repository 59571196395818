/*
**  Powered by WOPR Team's development
*/


import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import  { setRedirectedPath } from "../../store/actions/routing";


const PrivateRoute = ({component: Component, auth, ...rest }) => {

    rest.setRedirectedPath(rest.path);

    return (
        <Route
            {...rest}
            render={props =>
                auth.isAuthenticated === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect to='/login'/>
                )
            }
        />)
};


const mapState = state => ({
    auth: state.auth,
});


export default connect(mapState, { setRedirectedPath })(PrivateRoute);
