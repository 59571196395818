/*
**  Powered by WOPR Team's development
*/


import { SET_REDIRECTED_PATH, SWITCH_TAB }  from './types';


export const setRedirectedPath = (redirected_path) => ({
   type: SET_REDIRECTED_PATH,
   payload: {
       redirected_path: redirected_path,
   }
});


export const switchTab = (active_tab) => ({
    type: SWITCH_TAB,
    payload: {
        active_tab: active_tab,
    }
});