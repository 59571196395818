/*
**  Powered by WOPR Team's development
*/


import  axios                                                       from "axios"
import  React                                                       from "react";
import  {Table, Container, Header, Icon, Segment, Button }          from 'semantic-ui-react'
import  {Link}                                                      from "react-router-dom";
import  utils                                                       from '../../../utils'
import  MembersCTR                                                  from "../../../controllers/members";
import { COLORS }                   from "../../../static/config/colors"



class PentestsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pentests: [],
            users: [],
            pentest_users: [],
            temp_pentest_user_ids: [],
            temp_obj: "",
            temp_perimeter: "",
        }
    }


    componentDidMount() {
        this.getMembersList();
        this.getPentestList();
        this.getUsersAndPerimeterForAllPentests();
        document.body.style.backgroundColor = COLORS.BACKGROUND_COLOR
    }

    async getMembersList() {
        let l_members_options = await MembersCTR.list();
        l_members_options = await l_members_options.map((member) => ({ key: member.id, text: member.username, value: member.username}));

        await this.setState({
            pentest_users: l_members_options,
        })
    }

    async getPentestList() {
        try {
            let request = await axios.create({baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.get("/pentests", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
            await this.setState({ pentests: response.data.pentests });
            return response.data.pentests;
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    async getPentestUserIds(pentestId) {
        let request = await axios.create({baseURL: process.env.REACT_APP_API_BASE_ROUTE});
        let response = await request.get("/pentests/" + pentestId + "/users", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
        this.setState({
            temp_obj : response.data,
        });
    }

    async getPerimeter(pentestId) {
        let request = await axios.create({baseURL: process.env.REACT_APP_API_BASE_ROUTE});
        let response = await request.get("/pentests/" + pentestId + "/hosts", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
        this.setState({
            temp_perimeter : response.data,
        });
    }

    generateColorFromString(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var j = 0; j < 3; j++) {
            var value = (hash >> (j * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    async getUsersAndPerimeterForAllPentests() {
        while (this.state.pentests.length <= 0)
        {
            await new Promise(r => setTimeout(r, 10));
        }

        this.state.pentests.forEach(async (pentest) =>{
            pentest.parsed_users = "";
            // retrieve perimeter for this pentest
            await this.getPerimeter(pentest.id);
            while (this.state.temp_perimeter === null)
            {
                await new Promise(r => setTimeout(r, 10));
            }
            pentest.perimeter = "";
            let first = true;
            if (this.state.temp_perimeter.hosts !== undefined)
            {
                this.state.temp_perimeter.hosts.forEach(async (host) => {
                    if (first === false) {
                        pentest.perimeter = pentest.perimeter + " -- ";
                    }
                    first = false;
                    pentest.perimeter = pentest.perimeter + host.hostname;
                    pentest.color = this.generateColorFromString(pentest.name);
                });
            }

            // retrieve users for this pentest
            await this.getPentestUserIds(pentest.id);
            while (this.state.temp_obj === null)
            {
                await new Promise(r => setTimeout(r, 10));
            }

            first = true;
            if (this.state.temp_obj.messages !== undefined)
            {
                this.state.temp_obj.messages.forEach(async (member_infos) => {
                    if (first !== true)
                        pentest.parsed_users = pentest.parsed_users + " & ";
                        first = false;
                    pentest.parsed_users = pentest.parsed_users + member_infos.user_id;
                    pentest.color = this.generateColorFromString(pentest.name);
                });
            }
            // reset temp values and reload the page with new member/perimeter data
            this.setState({
                temp_obj: "",
                temp_perimeter: ""
            });
        });
    }

    renderPentests() {

        return this.state.pentests.map((pentest) => {
            return (
                    <Table.Row key={pentest.id} textAlign='center' style={{ backgroundColor: pentest.color}}>
                    <Table.Cell>{pentest.name}</Table.Cell>
                    <Table.Cell>{pentest.kind}</Table.Cell>
                    <Table.Cell>{pentest.perimeter}</Table.Cell>
                    <Table.Cell>{utils.formatDate(pentest.date_start)}</Table.Cell>
                    <Table.Cell>{utils.formatDate(pentest.date_end)}</Table.Cell>
                    <Table.Cell>{pentest.parsed_users}</Table.Cell>
                    <Table.Cell><Button  as={Link} to={"/pentests/" + pentest.id}>Show pentest</Button></Table.Cell>
                </Table.Row>
            )
        });
    }


    render() {
        return (
            <Container textAlign="center" >
            <Segment style={{backgroundColor: COLORS.SEGMENTS_COLOR}}>
                <Button style={{ backgroundColor: COLORS.BUTTON_BACKGRND_COLOR, color: COLORS.BUTTON_TEXT_COLOR}} primary as={Link} to="/pentests/new"> New Pentest</Button>
            </Segment>
            <Segment style={{backgroundColor: COLORS.SEGMENTS_COLOR}}>
                <Header as='h2' icon>
                    <Icon name='sitemap' />
                    Pentests
                    <Header.Subheader>
                        Manage all your pentests
                    </Header.Subheader>
                </Header>
                <Table celled>
                    <Table.Header>
                        <Table.Row textAlign='center'>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Kind</Table.HeaderCell>
                            <Table.HeaderCell>Perimeter</Table.HeaderCell>
                            <Table.HeaderCell>Date Start</Table.HeaderCell>
                            <Table.HeaderCell>Date End</Table.HeaderCell>
                            <Table.HeaderCell>Members</Table.HeaderCell>
                            <Table.HeaderCell>Show</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { this.renderPentests() }
                    </Table.Body>
                </Table>
            </Segment>
            </Container>
        )
    }
}


export default PentestsList;
