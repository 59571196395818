/*
**  Powered by WOPR Team's development
*/


import  axios           from "axios"

const FormData = require('form-data');

class ModulesCTR {

    static async list() {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE})
            let response = await request.get("/modules", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}})
            return response.data.modules
        } catch (err) {
            console.error(err)
            return []
        }
    }

    static async retrieve(id) {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE})
            let response = await request.get("/modules/" + id, { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}})
            return response.data.module
        } catch (err) {
            console.error(err)
            return null
        }
    }

    static async upload(data) {
        try{
            let form_data = new FormData()

            form_data.append('file', data.file)
            form_data.append('name', data.name)
            form_data.append('scope', data.scope)
            form_data.append('help', data.help)
            form_data.append('tags', data.tags)
            form_data.append('example', data.example)
            form_data.append('description', data.description)

            const config = {
                headers: { 'content-type': 'multipart/form-data',
                            'Authorization': 'Token ' + localStorage.getItem("auth_token")
                        }
            }

            let response = await axios.post("http://127.0.0.1:8000/modules", form_data, config)

            return response.data.module
        } catch (err) {

        }
    }
}


export default ModulesCTR;
