/*
**  Powered by WOPR Team's development
*/


import  React                                                           from "react";
import {Container, Form, Grid, Header, Segment, Divider, Dropdown, Input} from 'semantic-ui-react'
import  ModulesCTR                                                      from "../../../controllers/modules";
import  autoBind                                                        from "auto-bind";
import "react-datepicker/dist/react-datepicker.css";
import "../../../static/css/wopr.css";

class NewModule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            module_file: null,
            module_path: "",
            scope_options: [],
            scope: "",
            error_popup_show: false
        };
        autoBind(this);
    }

    async componentDidMount() {
        try {
            let l_scope_option=['public', 'private'];
            l_scope_option = l_scope_option.map((scope) => ({key: scope, text: scope, value: scope}));
            this.setState({
                scope_options: l_scope_option,
            })
        } catch (err) {
            console.error(err);
        }
    }

    async submit(data) {
        let res = await ModulesCTR.upload({
            file: this.state.module_file,
            name: this.state.module_name,
            scope: this.state.scope,
            help: "toto",
            tags: ['toto', 'tutu'],
            example: "toto",
            description: "toto",
        });
        if (res)
            this.props.history.push('/modules')
        else
            this.setState({
                error_popup_show: true
            });
    }

    async handleChange(event, { name, value }) {
        event.persist();
        if (name === "module_file")
        {
            await this.setState({ [name]: event.target.files[0] })
        }
        else
            await this.setState({ [name]: value })

    }

    render() {
        return (
            <Container>
            <Grid centered style={{ height: '100vh' }}>
                <Grid.Column style={{ maxWidth: 700, textAlign: 'center'}}>
                    <Divider hidden />
                    <Header as='h1' textAlign='center'>
                        Upload new module
                    </Header>
                    <Form size='large'>
                        <Segment stacked>
                            {/* JOB NAME */}
                            <Form.Field
                                control={Input}
                                fluid
                                name='module_name'
                                label="Name"
                                placeholder='Give a name to your module'
                                onChange={ this.handleChange }
                                value={this.state.module_name}
                            />

                            {/* PATH */}
                            <Form.Field
                                placeholder="/module/path"
                                name="module_file"
                                label="Module path"
                                control={Input}
                                type="file"
                                ref={this.fileInput}
                                onChange={ this.handleChange }
                                value={this.fileInput}
                            />

                            {/* SCOPE */}
                            <Form.Field
                                placeholder="public or private"
                                name="scope"
                                label="Scope"
                                control={Dropdown}
                                fluid
                                search
                                selection
                                onChange={this.handleChange}
                                options={this.state.scope_options}
                                value={this.state.scope}
                            />
                            <Form.Button onClick={this.submit}> Submit it </Form.Button>
                            <div>
                                {
                                this.state.error_popup_show?
                                    <p style={{color: "red"}}>Error : The form must be fully filled.<br/>Or the module name already exists.</p>
                                    : null
                                }
                            </div>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
            </Container>
        )
    }

}


export default NewModule;
