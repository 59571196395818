/*
**  Powered by WOPR Team's development
*/


import  React, { Component }                from 'react'
import  { Container, Divider, Header }      from 'semantic-ui-react'
import  NavBarLayout                        from "../layouts/NavBarLayout"
import  { switchTab }                       from "../../store/actions/routing";
import  { connect }                         from "react-redux"
import { COLORS }                   from "../../static/config/colors"


const HomepageHeader = ({ mobile }) => {
    return (
        <Container text >
            <Header
                as='h1'
                content='WOPR Platform'
                inverted
                style={{
                    fontSize: mobile ? '2em' : '4em',
                    fontWeight: 'normal',
                    marginBottom: 0,
                    marginTop: mobile ? '1.5em' : '3em',
                }}
            />
            <Header
                as='h2'
                content='Pentest the planet'
                inverted
                style={{
                    fontSize: mobile ? '1.5em' : '1.7em',
                    fontWeight: 'normal',
                    marginTop: mobile ? '0.5em' : '1.5em',
                }}
            />
        </Container>
    )
};


class Home extends Component {

    componentDidMount() {
        this.props.switchTab('login');
        document.body.style.backgroundColor = COLORS.BACKGROUND_COLOR
    }

    render() {
        return (
            <NavBarLayout  header={<HomepageHeader/>} >
                <Divider hidden />
                <Divider hidden />
            </NavBarLayout>
        )
    }
}


export default connect((state) => { return { routing: state.routing }}, { switchTab })(Home);
