/*
**  Powered by WOPR Team's development
*/


import	React										from "react";
import	{ BrowserRouter, Route, Switch, Redirect }	from "react-router-dom";
import 	PrivateRoute								from './PrivateRoute';
import	Home										from "../pages/Home";
import	Login										from "../pages/Login";
import  Pentests                                    from "../pages/pentests/Pentests";
import  Epics                                       from "../pages/epics/Epics";
import  Modules                                     from "../pages/modules/Modules";
import  Scenarios                                   from "../pages/scenarios/Scenarios";
import  Orackle                                     from "../pages/orackle/Orackle";
import  Alohomora                                   from "../pages/alohomora/alohomora";
import  { connect }                                 from "react-redux";
import  { checkAuth }                               from "../../store/actions/auth";


class TabRoutes extends React.Component {

    render() {
        let default_route = this.props.auth.isAuthenticated
            ? <Redirect to="/home" />
            : <Redirect to="/login" />;

        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path='/login' component={Login}/>
                    <Route path="/home" component={Home} />
                    <PrivateRoute path="/pentests" component={Pentests} />
                    <PrivateRoute path="/scenarios" component={Scenarios} />
                    <PrivateRoute path="/epics" component={Epics} />
                    <PrivateRoute path="/modules" component={Modules} />
                    <PrivateRoute path="/orackle" component={Orackle} />
                    <PrivateRoute path="/alohomora" component={Alohomora} />
                    { default_route }
                </Switch>
            </BrowserRouter>
        );
    }
}


export default connect(
    (state) => { return { auth: state.auth }},
    { checkAuth },
)(TabRoutes);

