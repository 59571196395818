/*
**  Powered by WOPR Team's development
*/


import  { SET_REDIRECTED_PATH, SWITCH_TAB }     from '../actions/types';


let init = {
    redirected_path: '/',
    active_tab: 'home',
};


export default function(state = init, action) {
    switch (action.type) {
        case SET_REDIRECTED_PATH: {
            const { redirected_path } = action.payload;
            return {
                ...state,
                redirected_path: redirected_path,
            }
        }
        case SWITCH_TAB: {
            const { active_tab } = action.payload;
            return {
                ...state,
                active_tab: active_tab,
            }
        }
        default:
            return state;
    }
}