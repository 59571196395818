/*
**  Powered by WOPR Team's development
*/


import  React                                                           from "react"
import {Table, Form, Segment, Dropdown, Input, Button, Modal }          from 'semantic-ui-react'
import  HostCTR                                                         from "../../../controllers/hosts"
import  EpicsCTR                                                        from "../../../controllers/epics"
import  autoBind                                                        from "auto-bind"
import  PentestsCTR                                                     from  "../../../controllers/pentests"
import  ScreenshotsCTR                                                  from "../../../controllers/screenshots"
import  NessusCTR                                                       from "../../../controllers/nessus"
import  JobsCTR                                                         from "../../../controllers/jobs"
import "react-datepicker/dist/react-datepicker.css"
import "../../../static/css/wopr.css"
import "../../../static/css/showPentestPage.css"
import  axios                                                           from "axios"
import ScenariosCTR from "../../../controllers/scenarios"
import Tree from "../../layouts/Tree"
import  utils                                                           from "../../../utils"
import { Carousel } from 'react-carousel-minimal';
import Switch from "react-switch";
import HostsCTR from "../../../controllers/hosts"
import { COLORS }                   from "../../../static/config/colors"

class ShowPentest extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            pentest_id: 0,
            pentest_name: "",
            pentest_data: {},
            running_jobs: "",
            background_color: "",
            member_list: "",
            initial_perimeter: [],
            initial_perimeter_str: "",
            large_perimeter: "",
            new_epic_target_list: [],
            new_epic_target_list_option: [],
            new_epic_target: "",
            scenario_list_option: [],
            selected_scenario: "",
            date_start: "",
            date_end: "",
            screenshots: [],
            codi_url: "",
            is_firefox: typeof InstallTrigger !== 'undefined',
            add_data_type_options: [
                {key: "http_header", text: "http header", value: "http_header"},
                {key: "spider_start_url", text: "spider_start_url", value: "spider_start_url"},
                {key: "spider_avoid_url", text: "spider_avoid_url", value: "spider_avoid_url"}
            ],
            data_addition_selector: "",
            data_addition_value: "",
            additionnal_data: [],
            perimeter_modif_permanent: false,
            perimeter_red_background: "",
            target_list_text: "Targets list for the next epic",
            nessus_report: "",
            xToken: "",
            authToken: "",
            info_modal_open: false,
            info_modal_text: "",
            nessus_download_report: "disabled",
            nessus_download_report_text: "Nessus scan not done yet"
        }

        autoBind(this)
    }

    async componentDidMount() {
        document.body.style.backgroundColor = this.state["perimeter_red_background"]

        let pentest_id = this.props.location.pathname.split('/')[2]

        let request = await axios.create({baseURL: process.env.REACT_APP_API_BASE_ROUTE})

        let pentest_resp = await request.get("/pentests/" + pentest_id, { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}})
        let background_color = this.generateColorFromString(pentest_resp.data.pentest.name)
        document.body.style.backgroundColor = background_color

        let scenario_list_array = await ScenariosCTR.list()

        let scenario_list_option = []
        var i = 0
        for (i = 0; i < scenario_list_array.length; i++) {
            scenario_list_option.push({key: scenario_list_array[i].id, text: scenario_list_array[i].name, value: scenario_list_array[i].name})
        }

        let targets_resp =await HostCTR.listForPentest(pentest_id)
        let target_str = ""
        let initial_perimeter_array = []
        let temp_epic_target_option_array = []

        for (i = 0; i < targets_resp.length; i++) {
            if (i > 0)
                target_str = target_str + ", "

            var current_target = targets_resp[i].hostname
            target_str = target_str + current_target
            initial_perimeter_array.push(current_target)
            temp_epic_target_option_array.push({key: current_target, text: current_target, value: current_target })
        }

        let user_list = await request.get("/pentests/" + pentest_id + "/users", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
        let user_str = ""

        for (i = 0; i < user_list.data.messages.length; i++) {
            if (i > 0)
                user_str = user_str + ", "
            user_str = user_str + user_list.data.messages[i].user_id
        }
        let l_screenshots = await this.update_screenshots(pentest_id)

        let l_pentest = await PentestsCTR.retrieve(pentest_id)

        await this.setState({
            pentest_id: pentest_id,
            pentest_name: pentest_resp.data.pentest.name,
            pentest_data: l_pentest.data,
            background_color: background_color,
            kind: l_pentest.kind,
            date_start: l_pentest.date_start,
            date_end: l_pentest.date_end,
            initial_perimeter_str: target_str,
            initial_perimeter: initial_perimeter_array,
            member_list: user_str,
            new_epic_target_list: initial_perimeter_array,
            new_epic_target_list_option: temp_epic_target_option_array,
            scenario_list_option: scenario_list_option,
            screenshots: l_screenshots,
            codi_url: l_pentest.codi_url
        })

        setTimeout(this.update_pentest_data, 5000)
    }

    async create_new_target(target_name, kind, pentest_id) {

        var data = {
            'kind': kind,
            'hostname': target_name,
            'scope': true,
            'pentest': pentest_id
        }
        await HostCTR.create(data)
    }

    async update_screenshots(pentest_id) {
        let screenshots = await ScreenshotsCTR.list_by_pentest_id(pentest_id)
        let updated_screenshots = []

        for (let i = 0; i < screenshots.length; i++) {
            let screenshot_data = await ScreenshotsCTR.retrieve(screenshots[i].id)
            updated_screenshots.push(screenshot_data)
        }
        return updated_screenshots
    }

    async update_pentest_data() {
        let l_updated_pentest = await PentestsCTR.retrieve(this.state.pentest_id)

        if (l_updated_pentest !== null) {

            if (l_updated_pentest.data !== null) {
                for (const [key, value] of Object.entries(JSON.parse(l_updated_pentest.data))) {
                    if (key === "nessus" && value != "" && this.state.nessus_download_report_text != "Processing...") {
                        await this.setState({
                                nessus_report : value,
                                nessus_download_report: "enabled",
                                nessus_download_report_text: "Get report"
                            })
                        this.renderNessusPart()
                    }
                }
            }

            if (this.state.pentest_data !== l_updated_pentest.data){
                await this.setState({
                    pentest_data : null,
                    screenshots: []
                });

                let updated_screenshots = this.update_screenshots(this.state.pentest_id)

                await this.setState({
                    pentest_data : l_updated_pentest.data,
                    screenshots: updated_screenshots,
                    nessus_report: l_updated_pentest.data["nessus"]
                });
            }
        }
        let l_running_jobs = await JobsCTR.list_running_jobs(this.state.pentest_id)
        let l_current_jobs = ""
        for (var i = 0; i < l_running_jobs.length; i++)
        {
            if (i > 0)
                l_current_jobs = l_current_jobs + ", "
                l_current_jobs = l_current_jobs + l_running_jobs[i]['name']
        }

        await this.setState({
            running_jobs : l_current_jobs,
        });


        setTimeout(this.update_pentest_data, 5000)
    }

    componentWillUnmount() { clearInterval(this.interval); }

    async handleChange(event, { name, value }){
        if (this.state.perimeter_modif_permanent && name === "new_epic_target_list") {
            let diff = this.state.new_epic_target_list.filter(x => !value.includes(x))
            var hosts_list = await HostsCTR.listForPentest(this.state.pentest_id)
            var iterator = 0
            while (iterator < hosts_list.length) {
                console.log(hosts_list[iterator])
                if (hosts_list[iterator]["hostname"] === diff[0]) {
                    await HostsCTR.delete(hosts_list[iterator]["id"])
                }
                iterator++
            }
            console.log(hosts_list)
        }
        await this.setState({ [name]: value });
    }

    async switchToggleChange(bool_value, event, id) {
        let perimeter_background_color = ""
        if (id === "perimeter_modif_permanent" && this.state[id] === false) {
            perimeter_background_color = "#ee4466"
        }
        await this.setState(
            {
                [id]: !this.state[id],
                perimeter_red_background: perimeter_background_color
            });
    }

    async addData() {
        if (this.state.data_addition_selector === "" || this.state.data_addition_value === ""){
            console.log("returned")
            return
        }
        console.log(this.state.data_addition_selector)
        console.log(this.state.data_addition_value)
        console.log({ [this.state.data_addition_selector] : this.state.data_addition_value})
        await this.setState(prevState => ({
            additionnal_data : [...prevState.additionnal_data, this.state.data_addition_selector + ":" + this.state.data_addition_value],
            data_addition_value : "",
            data_addition_selector: ""
        }));
    }

    async addTarget() {
        var sanitized_new_epic_target = this.state.new_epic_target.replaceAll(" ", "").replaceAll(",", ".")
        var splited=[]
        if (sanitized_new_epic_target.startsWith("http")) {
            splited = sanitized_new_epic_target.split("/")
            if (splited.length > 2) {
                sanitized_new_epic_target = splited[2]
            }
        }
        if (sanitized_new_epic_target.charCodeAt(0) >= 48 && sanitized_new_epic_target.charCodeAt(0) <= 57) {
            splited = sanitized_new_epic_target.split("/")
            sanitized_new_epic_target = splited[0]
        }

        if (sanitized_new_epic_target === "")
            return
        var isInList = false
        for (var i = 0; i < this.state.new_epic_target_list.length; i++){
            if (this.state.new_epic_target_list[i] === sanitized_new_epic_target){
                isInList = true
                break
            }
        }
        if (!isInList) {
            var new_target_option_list = this.state.new_epic_target_list_option.slice()

            new_target_option_list.push({key: sanitized_new_epic_target, text: sanitized_new_epic_target, value: sanitized_new_epic_target })

            var new_target_list = this.state.new_epic_target_list.slice()
            new_target_list.push(sanitized_new_epic_target)

            await this.setState({
                new_epic_target_list: new_target_list,
                new_epic_target_list_option: new_target_option_list
            })
        }

        var kind = "domain"
        if (this.isIP(sanitized_new_epic_target)) {
            kind = "ip"
        }
        if (this.state.perimeter_modif_permanent) {
            this.create_new_target(sanitized_new_epic_target, kind, this.state.pentest_id)
        }

        await this.setState({
            new_epic_target: ""
        })
    }

    generateColorFromString(str) {
        var hash = 0
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        var colour = '#'
        for (var j = 0; j < 3; j++) {
            var value = (hash >> (j * 8)) & 0xFF
            colour += ('00' + value.toString(16)).substr(-2)
        }
        return colour
    }

    isIP(str) {
        const regex = new RegExp('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')
        if (regex.test(str))
            return true;
        return false;
    }

    getScenarioName(scenario_id) {
        for (var i = 0; i < this.state.scenario_list_option.length; i++){
            if (scenario_id === this.state.scenario_list_option[i].key){
                return this.state.scenario_list_option[i].text
            }
        }
    }

    getScenarioId(scenario_name) {
        for (var i = 0; i < this.state.scenario_list_option.length; i++){
            if (scenario_name === this.state.scenario_list_option[i].text){
                return this.state.scenario_list_option[i].key
            }
        }
    }

    async get_nessus_report() {
        console.log("nessus report : " + this.state.nessus_report)
        let response = await this.local_retrieve(this.state.nessus_report, this.state.pentest_name)
        await this.setState({
            nessus_download_report: "disabled",
            nessus_download_report_text: "Processing..."
        })
    }

    async local_retrieve(scan_id, pentest_name) {
        try {
            axios({
                url: process.env.REACT_APP_API_BASE_ROUTE + "/nessus/" + scan_id,
                headers: { "Authorization": "Token " + localStorage.getItem("auth_token")},
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'nessu_report_' + pentest_name + '.pdf');
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                this.setState({
                    nessus_download_report: "enabled",
                    nessus_download_report_text: "Get report"
                })
            }).catch(error => {
                this.setState({
                    info_modal_text: "Report not ready yet, the scan may take a while.",
                    info_modal_open: true,
                    nessus_download_report: "enabled",
                    nessus_download_report_text: "Get report"
                })
            });
        } catch (err) {
        }
    }

    async start_scenario() {
        let epic_ip_string = "["
        let epic_domain_string = "["

        if (this.state.new_epic_target_list !== undefined) {
            for (let i = 0; i < this.state.new_epic_target_list.length; i++) {
                if (this.isIP(this.state.new_epic_target_list[i])) {
                    if (epic_ip_string.length > 3) // meaning that we have more than just "["
                        epic_ip_string = epic_ip_string + ","
                    epic_ip_string = epic_ip_string + "\"" + this.state.new_epic_target_list[i] + "\""
                }
                else {
                    if (epic_domain_string.length > 3) // meaning that we have more than just "["
                        epic_domain_string = epic_domain_string + ","
                    epic_domain_string = epic_domain_string + "\"" + this.state.new_epic_target_list[i] + "\""
                }
            }
        }
        epic_ip_string = epic_ip_string + "]"
        epic_domain_string = epic_domain_string + "]"

        var l_scenario_id = this.getScenarioId(this.state.selected_scenario)
        //console.log(l_scenario_id)
        //console.log(this.state.selected_scenario)
        //console.log(this.state.scenario_list_option)

        await this.setState({
            selected_scenario: ""
        })

        await EpicsCTR.create({
            name: "Epic : " + this.state.selected_scenario,
            scenario_id: l_scenario_id,
            pentest_id: this.state.pentest_id,
            status: ".",
            epic_domain_list: epic_domain_string,
            epic_ip_list: epic_ip_string,
            related_jobs: "None",
            additionnal_data: this.state.additionnal_data
        })
    }

    async removeAdditionnalData(data_to_remove) {
        var new_array = this.state.additionnal_data

        for( var i = 0; i < new_array.length; i++){
            console.log("1 : " + new_array[i])
            console.log("2 : " + data_to_remove)
            if ( new_array[i] === data_to_remove) {
                new_array.splice(i, 1);
                i--;
            }
        }
        await this.setState({
            additionnal_data: new_array
        })
    }

    renderAdditionalData() {
        const listItems = this.state.additionnal_data.map((data) =>
            <a style={{ alignSelf: 'stretch', marginRight: 'auto', marginBottom: '3px'}} key={data} className="ui label" value={data}>{data}<i aria-hidden="true" className="delete icon" onClick = {() => this.removeAdditionnalData(data)}></i></a>
        );
        return (<div style={{ display: 'flex', flexDirection: 'column'}}>{listItems}</div>)
    }

    renderNessusPart() {
        return (
            <div style={{
                display: 'block', width: 600, padding: 5
            }}>
                <Button style={{backgroundColor: COLORS.BUTTON_BACKGRND_COLOR, color: COLORS.BUTTON_TEXT_COLOR}} className={this.state.nessus_download_report} onClick={() => this.get_nessus_report()} primary >{this.state.nessus_download_report_text}</Button>
            </div>
        )
    }

    renderScreenshots() {
        let data = [
        {
            image: "",
            caption: "No screenshots"
        }]

        if (this.state.screenshots.length > 0)
        {
            data = []
            for (const value of this.state.screenshots) {
                data.push({
                image: "data:image/jpg;base64," + value.image,
                caption: value.key
                })
            }
        }
        const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
        }
        const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
        }
        return (
        <div style={{
        display: 'block', width: 600, padding: 5
        }}>
            <h2>Screenshots</h2>
            <div style={{
                padding: "0 20px"
            }}>
                <Carousel
                data={data}
                time={7000}
                width="700px"
                height="400px"
                captionStyle={captionStyle}
                radius="10px"
                slideNumber={true}
                slideNumberStyle={slideNumberStyle}
                captionPosition="bottom"
                automatic={true}
                dots={false}
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                thumbnails={true}
                thumbnailWidth="100px"
                style={{
                    textAlign: "center",
                    maxWidth: "850px",
                    maxHeight: "500px",
                    margin: "30px auto",
                }}
                />
            </div>
        </div>
        );
    }

    renderCodiFrame() {
        if (this.state.is_firefox)
        {
            return(
                    <iframe width="100%" height="918" src={this.state.codi_url}></iframe>
            )
        }
        else
        return(
            <div>
                <a href={this.state.codi_url} target="_blank">CodiMD link HERE</a>
                <p>
                    To get CodiMD full embeded experience, you must use firefox
                </p>
            </div>
        );
    }

    async info_modal_close () {
        await this.setState({info_modal_open: false})
    }

    renderGlobalResult() {
        let l_pentest_result = {}
        try {
            if (this.state.pentest_data === null)
                return ([])
            l_pentest_result = JSON.parse(this.state.pentest_data)
        } catch (err) {
            console.error(err);
            return
        }

        //console.log("//////// renderGlobalResult \\\\\\\\\\\\\\\\\\")
        // Create data array
        let displayed_data = {}
        if (l_pentest_result === null)
            l_pentest_result = {}

        for (const [ip, categories] of Object.entries(l_pentest_result)) {
            if (ip === "nessus") {
                continue
            }

            var l_ip = ip
            var l_categories = categories
            var new_key = "/" + ip

            displayed_data[new_key] = {
                    path: new_key,
                    type: 'folder',
                    isRoot: true,
                    children: [],
                    info_found_count: 0
                }

            for (const [new_cat, data_array] of Object.entries(l_categories)) {
                // here are the categories to not display
                if (new_cat === "job_to_check" || new_cat === "additionnal_data")
                    continue

                let cat_data_amount = 0
                if (Array.isArray(data_array)) {
                    cat_data_amount = data_array.length
                }
                else {
                    cat_data_amount = Object.keys(data_array).length
                }
                // if no data is found in this category, we won's display it
                if (cat_data_amount === 0)
                    continue

                // if we are here, it means we have found some data, so we add the category to the page
                var new_sub_key = "/" + l_ip + "/" + new_cat
                displayed_data[new_sub_key] = {
                    path: new_sub_key,
                    type: 'folder',
                    isRoot: false,
                    children: [],
                    info_found_count: 0,
                }
                // add this node as a child to the parent
                displayed_data[new_key].children.push(new_sub_key)

                // "routes" data are not a simple array, it's an array of object
                if (new_cat === "routes" ||
                new_cat === "fuzz_admin" ||
                new_cat === "fuzz_archives" ||
                new_cat === "fuzz_repository" ||
                new_cat === "fuzz_common_files" ||
                new_cat === "fuzz_wsdl" ||
                new_cat === "fuzz_folder" ||
                new_cat === "fuzz_http_basic_auth" ||
                new_cat === "verb_tampering") {

                    let count_routes = 0

                    let encountered_code = []
                    let encountered_code_size = []
                    for (const [new_route, route_data] of Object.entries(data_array)) {

                        // Add new sub folder to 'routes' if needed
                        // there will be 1 sub folder for each different encountered http code
                        if (!(encountered_code.includes(route_data['status']))) {
                            var new_http_code_array_key = "/" + l_ip + "/" + new_cat + "/" + route_data['status']
                            displayed_data[new_http_code_array_key] = {
                                path: new_http_code_array_key,
                                type: 'folder',
                                display: "code : " + route_data['status'],
                                isRoot: false,
                                children: [],
                                info_found_count: 0
                            }

                            displayed_data[new_key + '/' + new_cat]['children'].push(new_http_code_array_key)
                            encountered_code.push(route_data['status'])
                        }

                        // Here we add the actual new data inside its http return code folder
                        var new_data_array_key = "/" + l_ip + "/" + new_cat + "/" + route_data['status'] + "/" + new_route.replaceAll('/', '_')

                        var route_to_display = new_route
                        if (new_cat === "fuzz_admin" ||
                            new_cat === "fuzz_archives" ||
                            new_cat === "fuzz_repository" ||
                            new_cat === "fuzz_common_files" ||
                            new_cat === "fuzz_wsdl" ||
                            new_cat === "fuzz_folder" ||
                            new_cat === "fuzz_http_basic_auth") {
                            //route_to_display += " => size : " + route_data["length"]

                            // We need to create a new folder for each new size of page
                            if (!(encountered_code_size.includes(route_data["length"]))) {

                                var new_size_array_key = "/" + l_ip + "/" + new_cat + "/" + route_data['status'] + "/" + route_data["length"]
                                displayed_data[new_size_array_key] = {
                                    path: new_size_array_key,
                                    type: 'folder',
                                    display: "size : " +route_data["length"],
                                    isRoot: false,
                                    children: [],
                                }
                                displayed_data[new_key + '/' + new_cat + '/' + route_data['status']]['children'].push(new_size_array_key)
                                encountered_code_size.push(route_data["length"])
                            }
                            new_data_array_key = "/" + l_ip + "/" + new_cat + "/" + route_data['status'] + "/" + route_data["length"] + "/" + new_route.replaceAll('/', '_')
                            displayed_data[new_data_array_key] = {
                                path: new_key + '/' + new_cat,
                                type: 'file',
                                display: route_to_display,
                                isRoot: false,
                                data: route_to_display,
                                children: [],
                            }
                            displayed_data[new_key + '/' + new_cat + '/' + route_data['status'] + "/" + route_data["length"]].children.push(new_data_array_key)
                            displayed_data[new_key + '/' + new_cat + '/' + route_data['status']].info_found_count += 1
                            count_routes += 1
                        }
                        else {
                            displayed_data[new_data_array_key] = {
                                path: new_key + '/' + new_cat,
                                type: 'file',
                                display: route_to_display,
                                isRoot: false,
                                data: route_to_display,
                                children: [],
                            }
                            displayed_data[new_key + '/' + new_cat + '/' + route_data['status']].children.push(new_data_array_key)
                            count_routes += 1
                        }
                    }

                    displayed_data[new_sub_key].info_found_count = count_routes
                    displayed_data[new_key].info_found_count += cat_data_amount
                }
                else if (   new_cat === "xss_get_injection" ||
                            new_cat === "xss_post_injection"  ) {
                    var token = "<>'\""
                    for (const [url, array] of Object.entries(data_array)) {
                        var current_xss_get_url = "/" + l_ip + "/" + new_cat + "/" + url.replaceAll('/', '_')
                        displayed_data[current_xss_get_url] = {
                            path: current_xss_get_url,
                            type: 'folder',
                            display: url,
                            isRoot: false,
                            children: [],
                            info_found_count: 0
                        }
                        displayed_data[new_key + '/' + new_cat]['children'].push(current_xss_get_url)

                        for (const [char_key, param_array] of  Object.entries(array)) {
                            new_sub_key = "/" + l_ip + "/" + new_cat + "/" + url.replaceAll('/', '_') + char_key
                            displayed_data[new_sub_key] = {
                                path: new_sub_key,
                                type: 'file',
                                display: token.charAt(char_key) + " : " + param_array,
                                isRoot: false,
                                children: [],
                                info_found_count: 0
                            }
                            displayed_data[current_xss_get_url]['children'].push(new_sub_key)
                        }
                    }
                }
                else if ( new_cat === "SQLi") {
                    for (const [url, patern_SQLi] of Object.entries(data_array)) {
                        var current_SQLi_url = "/" + l_ip + "/" + new_cat + "/" + url.replaceAll('/', '_')
                        displayed_data[current_SQLi_url] = {
                            path: current_SQLi_url,
                            type: 'folder',
                            display: url,
                            isRoot: false,
                            children: [],
                            info_found_count: 0
                        }
                        displayed_data[new_key + '/' + new_cat]['children'].push(current_SQLi_url)

                        for (const patern of Object.entries(patern_SQLi)) {
                            var value_to_display = patern
                            value_to_display = patern + " "
                            value_to_display = value_to_display.substr(2,value_to_display.length - 2).replaceAll("(simpleQuotes)", "'")
                            var current_SQLi_patern = "/" + l_ip + "/" + new_cat + "/" + url.replaceAll('/', '_') + "/" + patern
                            displayed_data[current_SQLi_patern] = {
                                    path: current_SQLi_patern,
                                    type: 'file',
                                    display: value_to_display,
                                    isRoot: false,
                                    children: [],
                                    info_found_count: 0
                                }
                                displayed_data[current_SQLi_url]['children'].push(current_SQLi_patern)
                        }
                    }
                }
                // else : data are in simple arrays
                else {
                    displayed_data[new_key].info_found_count += cat_data_amount
                    for (const simple_data of data_array) {
                        new_data_array_key = "/" + l_ip + "/" + new_cat + "/" + simple_data.replaceAll('/', '_')
                        displayed_data[new_data_array_key] = {
                            path: new_data_array_key,
                            type: 'file',
                            display: simple_data,
                            isRoot: false,
                            data: simple_data,
                            children: [],
                        }
                        displayed_data[new_sub_key].children.push(new_data_array_key)
                    }
                }
            }
        }

        return (
            <div style={{
                display: 'block', width: 700, padding: 30
            }}>
                <h2>Pentest data</h2>
                <Tree
                    nodes = {displayed_data}
                />
                <button onClick={this.downloadJsonExtract}>Download json extract</button>
            </div>
        )
    }

    downloadJsonExtract = () => {
        const element = document.createElement("a");
        /*console.log(JSON.stringify(this.state.pentest_data).replaceAll('\\\"', "\"").slice(1,-1))*/
        const file = new Blob([JSON.stringify(this.state.pentest_data).replaceAll('\\\"', "\"").slice(1,-1)], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = this.state.pentest_name + "_extract.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      }

    render() {

        var perimeter_str = ""
        for (const elem of this.state.initial_perimeter) {
            perimeter_str = perimeter_str + " -- " + elem
        }
        return (

            <div className="global_container_show_pentest">
            <Modal
                size='tiny'
                open={this.state.info_modal_open}
            >
                <Modal.Content>
                    <p>{this.state.info_modal_text}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={ () => this.info_modal_close() }>
                        Ok
                    </Button>
                </Modal.Actions>
            </Modal>
                <div className="container_show_pentest">

                    {/* Here is the LEFT side  */}
                    <div className="left">
                        <Segment className="segment_show_pentest" style={{backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}}>
                            <h1>{this.state.pentest_name}</h1>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row textAlign='center'>
                                        <Table.HeaderCell>Kind</Table.HeaderCell>
                                        <Table.HeaderCell>Perimeter</Table.HeaderCell>
                                        <Table.HeaderCell>Date Start</Table.HeaderCell>
                                        <Table.HeaderCell>Date End</Table.HeaderCell>
                                        <Table.HeaderCell>Members</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row key={this.state.id} textAlign='center'>
                                        <Table.Cell>{this.state.kind}</Table.Cell>
                                        <Table.Cell>{perimeter_str}</Table.Cell>
                                        <Table.Cell>{utils.formatDate(this.state.date_start)}</Table.Cell>
                                        <Table.Cell>{utils.formatDate(this.state.date_end)}</Table.Cell>
                                        <Table.Cell>{this.state.member_list}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                        <Segment className="segment_show_pentest start_new_scenario" style={{backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}} >
                            <h3>Manage perimeter</h3>
                            <Form.Group grouped>
                            <Form.Field
                                    name='new_epic_target'
                                    control={Input}
                                    action={{ icon: 'add', onClick: this.addTarget }}
                                    fluid
                                    placeholder='Add an IP address or a domain'
                                    onChange={ this.handleChange }
                                    value={this.state.new_epic_target}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "0.5em", marginBottom: "1em"}}>
                                <label><h5 >Make target list changes persistent</h5></label>
                                <Switch
                                    id='perimeter_modif_permanent'
                                    onColor="#820"
                                    onChange={this.switchToggleChange }
                                    checked={this.state.perimeter_modif_permanent}
                                />
                            </div>
                            <label><h4>Targets list for the next epic</h4></label>
                            <Form.Field
                                control={Dropdown}
                                name='new_epic_target_list'
                                fluid
                                multiple
                                selection
                                options={this.state.new_epic_target_list_option}
                                placeholder=''
                                onChange={ this.handleChange }
                                value={this.state.new_epic_target_list}
                            />
                            </Form.Group>
                        </Segment>
                        <Segment className="segment_show_pentest start_new_scenario" style={{backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}} >
                            <Form.Group grouped>
                                <h3>Start a new epic</h3>

                                <label><h4>Scenarios</h4></label>
                                <Form.Field
                                    control={Dropdown}
                                    name='selected_scenario'
                                    fluid
                                    selection
                                    options={this.state.scenario_list_option}
                                    placeholder='Select a scenario'
                                    onChange={ this.handleChange }
                                    value={this.state.selected_scenario}
                                />
                            </Form.Group>
                            <br/>
                            {this.renderAdditionalData()}
                            <br/>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Modal trigger={<Button>New additional data</Button>}>
                                    <Modal.Header>Add new data</Modal.Header>
                                    <Modal.Content>
                                        <Modal.Description>
                                            <Form.Field
                                                control={Dropdown}
                                                name='data_addition_selector'
                                                fluid
                                                selection
                                                placeholder='Select data type'
                                                options={this.state.add_data_type_options}
                                                onChange={ this.handleChange }
                                                value={this.state.data_addition_selector}
                                            />
                                            <br/>
                                            <Form.Field
                                                name='data_addition_value'
                                                control={Input}
                                                fluid
                                                placeholder='data'
                                                onChange={ this.handleChange }
                                                value={this.state.data_addition_value}
                                            />
                                            <br/>
                                            <Button primary onClick={() => this.addData()}>Add</Button>
                                        </Modal.Description>
                                    </Modal.Content>
                                </Modal>
                                <Button style={{backgroundColor: COLORS.BUTTON_BACKGRND_COLOR, color: COLORS.BUTTON_TEXT_COLOR}}  primary onClick={() => this.start_scenario()}> Start scenario</Button>
                            </div>
                        </Segment>
                        <Segment className="segment_show_pentest start_new_scenario" style={{backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}} >
                            <h2>Nessus</h2>
                        {
                            this.renderNessusPart()
                        }
                        </Segment>
                        <Segment className="segment_show_pentest start_new_scenario" style={{backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}} >
                            <h2>Currently running jobs</h2>
                            {this.state.running_jobs}
                        </Segment>
                        <Segment className="segment_show_pentest start_new_scenario" style={{backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}} >
                        {
                            this.renderScreenshots()
                        }
                        </Segment>
                    </div>
                    {/* end of the LEFT side  */}

                    {/* Here is the whole RIGHT side  */}
                    <div className="right">
                        <Segment className="segment_show_pentest" style={{backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}} >
                        {
                            this.renderGlobalResult()
                        }
                        </Segment>
                        <Segment className="segment_show_screenshots" style={{backgroundColor: COLORS.ARRAY_BACKGRND_COLOR}} >
                        {
                            this.renderCodiFrame()
                        }
                        </Segment>
                    </div>
                    {/* end of the RIGHT side  */}

                </div>

            </div>
        )
    }

}


export default ShowPentest
