/*
**  Powered by WOPR Team's development
*/


import  axios           from "axios"


class HostsCTR {

    static async create(data) {
        try {
            let request = await axios.create({ method: "post", baseURL: process.env.REACT_APP_API_BASE_ROUTE, headers: { "Authorization": "Token " + localStorage.getItem("auth_token")} });
            await request.post("/hosts", data);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    static async listForPentest(pentest_id) {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.get("/pentests/" + pentest_id + "/hosts", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
            return response.data.hosts;
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    static async retrieve(id) {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.get("/hosts/" + id, { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
            return response.data.scenarios;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    static async delete(id) {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.delete("/hosts/" + id, { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
            return response.data.scenarios;
        } catch (err) {
            console.error(err);
            return null;
        }
    }


}


export default HostsCTR;
