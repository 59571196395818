/*
**  Powered by WOPR Team's development
*/


import  React                                                       from "react";
import {Table, Container, Header, Icon, Segment, Loader}            from 'semantic-ui-react'
import EpicCTR                                                      from  "../../../controllers/epics";
import PentestCTR                                                   from  "../../../controllers/pentests";
import ScenariosCTR                                                 from  "../../../controllers/scenarios";
import HostCTR                                                      from  "../../../controllers/hosts";
import JobsCTR                                                      from "../../../controllers/jobs";
import EpicOutputModal                                              from "./EpicOutputModal";

class EpicsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            epics: [],
            pentest_by_id: [],
            scenario_name_by_id: [],
            hosts_by_scenario_id: {}
        }
    }


    componentDidMount() {
        this.getPentestInfos()
    }

    async getPentestInfos()
    {
        await this.createPentestByIdDict()
        for (let i = 0; i < this.state.pentest_by_id.length; i++)
            console.log(this.state.pentest_by_id[i].id)
        await this.createScenarioNameByIdDict()
        this.getEpicsList()

    }

    async createPentestByIdDict() {
        let l_pentest_list = await PentestCTR.list()

        // here we fill a dit so we can retrieve easily a pentest ID with its name
        let l_pentest_by_id = []
        for (let i = 0; i < l_pentest_list.length; i++) {
            l_pentest_by_id[l_pentest_list[i].id] = l_pentest_list[i]
        }
        await this.setState({
            pentest_by_id: l_pentest_by_id,
        })
        this.render()

    }

    async createScenarioNameByIdDict() {
        let l_scenario_list = await ScenariosCTR.list()

        // here we fill a dit so we can retrieve easily a scenario ID with its name
        let l_scenario_name_by_id = {}

        for (let i = 0; i < l_scenario_list.length; i++) {
            l_scenario_name_by_id[l_scenario_list[i].id] = l_scenario_list[i].name
        }

        await this.setState({
            scenario_name_by_id: l_scenario_name_by_id,
        })
    }

    async getEpicsList() {
        try {
            let l_epics = await EpicCTR.list();

            for (let i = 0; i < l_epics.length; i++) {
                if (l_epics[i].related_jobs === "None") {
                    console.log("Epic number : " + l_epics[i].id + " has a None glados ID")
                    continue
                }
                var Glados_job = await JobsCTR.retrieve(l_epics[i].related_jobs)
                l_epics[i].status = Glados_job.status
                l_epics[i].job_data = Glados_job.formated_output

                if (typeof this.state.pentest_by_id[l_epics[i].pentest] === 'undefined')
                    break
                l_epics[i].color = this.generateColorFromString(this.state.pentest_by_id[l_epics[i].pentest].name)

                let hosts_str = ""
                let targets
                let new_dict = this.state.hosts_by_scenario_id;

                // here we cache the perimeter of each pentest to prevent repeating requests multiple times
                if (this.state.hosts_by_scenario_id[this.state.pentest_by_id[l_epics[i].pentest].id] === undefined) {
                    targets = await HostCTR.listForPentest(this.state.pentest_by_id[l_epics[i].pentest].id)
                    new_dict[this.state.pentest_by_id[l_epics[i].pentest].id] = targets
                    await this.setState({
                        hosts_by_scenario_id: new_dict,
                    })
                }
                else {
                    targets = new_dict[this.state.pentest_by_id[l_epics[i].pentest].id]
                }

                for (let i = 0; i < targets.length; i++) {
                    hosts_str = targets[i].hostname + "," + hosts_str
                }
                l_epics[i].targets = hosts_str
            }
            await this.setState({
                epics: l_epics,
            })

        } catch (err) {
            console.error(err);
        }
    }

    generateColorFromString(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var j = 0; j < 3; j++) {
            var value = (hash >> (j * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    renderEpics() {

        return this.state.epics.map((epic) => {

            switch (epic.status) {
                case 'RUNNING':
                    epic.status = <Loader active inline />;
                    break;
                case 'PAUSE':
                    epic.status = <Icon name="pause" />;
                    break;
                case 'ERROR':
                    epic.status = <Icon name="close" />;
                    break;
                default:
                    epic.status = <Icon name='checkmark' />
            }

            return (
                <Table.Row key={epic.id} textAlign='center' style={{ backgroundColor: epic.color}}>
                    <Table.Cell>{epic.name}</Table.Cell>
                    <Table.Cell>{this.state.pentest_by_id[epic.pentest].name}</Table.Cell>
                    <Table.Cell>{this.state.scenario_name_by_id[epic.scenario]}</Table.Cell>
                    <Table.Cell>{epic.targets}</Table.Cell>
                    <Table.Cell>{epic.status}</Table.Cell>
                    <Table.Cell><EpicOutputModal job_data={epic.job_data}/></Table.Cell>
                </Table.Row>
            )
        });
    }

    render() {
        return (
            <Container textAlign="center">
                    <Segment>
                    <Header as='h2' icon>
                        <Icon name='code' />
                        Epics
                        <Header.Subheader>
                            Manage all your epics
                        </Header.Subheader>
                    </Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row textAlign='center'>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Pentest</Table.HeaderCell>
                                <Table.HeaderCell>Scenario</Table.HeaderCell>
                                <Table.HeaderCell>Targets</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Output</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            { this.renderEpics() }
                        </Table.Body>
                    </Table>
                </Segment>
            </Container>
        )
    }
}


export default EpicsList;
