
// https://www.color-hex.com/color-palette/1294
const custom1_BACKGROUND_COLOR        = '#1b1b1b'
const custom1_BUTTON_TEXT_COLOR       = '#b3cde0'
const custom1_SEGMENTS_COLOR          = '#6497b1'
const custom1_ARRAY_BACKGRND_COLOR    = '#b3cde0'
const custom1_BUTTON_BACKGRND_COLOR   = '#03396c'

const RED_NEGATIVE = "#e0301e"
const RED_NEGATIVE_TEXT = "#ffffffff"

const GREEN_POSITIVE = "#39f123"
const GREEN_POSITIVE_TEXT = "#1b1b1b"

const BLUE_NEUTRAL = "#a7d8de"
const BLUE_NEUTRAL_TEXT = "#1b1b1b"


export const COLORS = {
    BACKGROUND_COLOR        : custom1_BACKGROUND_COLOR     ,
    BUTTON_TEXT_COLOR       : custom1_BUTTON_TEXT_COLOR    ,
    SEGMENTS_COLOR          : custom1_SEGMENTS_COLOR       ,
    ARRAY_BACKGRND_COLOR    : custom1_ARRAY_BACKGRND_COLOR ,
    BUTTON_BACKGRND_COLOR   : custom1_BUTTON_BACKGRND_COLOR,

    RED_NEGATIVE            : RED_NEGATIVE                  ,
    RED_NEGATIVE_TEXT       : RED_NEGATIVE_TEXT             ,

    GREEN_POSITIVE          : GREEN_POSITIVE                ,
    GREEN_POSITIVE_TEXT     : GREEN_POSITIVE_TEXT           ,

    BLUE_NEUTRAL            : BLUE_NEUTRAL                  ,
    BLUE_NEUTRAL_TEXT       : BLUE_NEUTRAL_TEXT
}

