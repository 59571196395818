/*
**  Powered by WOPR Team's development
*/


import  React                                                           from "react";
import {Container, Form, Grid, Header, Segment, Divider, Dropdown, Input} from 'semantic-ui-react'
import  ModulesCTR                                                      from "../../../controllers/modules";
import  autoBind                                                        from "auto-bind";
import  ScenarioCTR                                                     from  "../../../controllers/scenarios";
import  JSONInput                                                       from 'react-json-editor-ajrm';
import "react-datepicker/dist/react-datepicker.css";
import "../../../static/css/wopr.css";


class NewScenario extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: null,
            modules: [],
            module_options: [],
            new_module: null,
            new_module_order: -1
        };
        autoBind(this);
    }

    async componentDidMount() {
        try {
            let l_module_options = await ModulesCTR.list();

            l_module_options = await l_module_options.map((module) => ({ key: module.name, text: module.name, value: module.name }));
            await this.setState({
                module_options: l_module_options,
            });
            console.log(l_module_options)
        } catch (err) {
            console.error(err);
        }
    }

    async submit() {
        console.log(this.state)
        let res = await ScenarioCTR.create({
            name: this.state.name,
            modules: this.state.modules
        });
        console.log(this.state.modules)
        if (res)
            this.props.history.push('/scenarios')
        else
            this.setState({
                error_popup_show: true
            });
    }

    async handleChange(event, { name, value }) {
        await this.setState({ [name]: value });
    }

    async addModule() {
        if (this.state.new_module === null || this.state.new_module_order <= -1 || this.state.new_module_order > 100)
            return;
        let new_module_list = this.state.modules.slice();
        let new_pair = {module: this.state.new_module, order: this.state.new_module_order};
        new_module_list[new_module_list.length] = new_pair;

        await this.setState({
            modules: new_module_list,
            new_module: null,
            new_module_order: ""
        })

        console.log("modules", new_module_list);
    }

    render() {
        return (
            <Container>
            <Grid centered style={{ height: '100vh' }}>
                <Grid.Column style={{ maxWidth: 700, textAlign: 'center'}}>
                    <Divider hidden />
                    <Header as='h1' textAlign='center'>
                        Create a new scenario
                    </Header>
                    <Form size='large'>
                        <Segment stacked>

                            {/* SCENARIO NAME */}
                            <Form.Field
                                control={Input}
                                fluid
                                name='name'
                                label="Name"
                                placeholder='Give a name to your pentest'
                                onChange={this.handleChange}
                            />

                            {/* MODULE */}
                            <Form.Group grouped>
                                <label>Add module</label>
                                <Form.Field
                                    placeholder="Choose a module"
                                    control={Dropdown}
                                    name='new_module'
                                    fluid
                                    selection
                                    options={this.state.module_options}
                                    onChange={ this.handleChange }
                                    value={this.state.new_module}
                                />
                                <Form.Field
                                    control={Input}
                                    type="number"
                                    name="new_module_order"
                                    min="1"
                                    max="100"
                                    fluid
                                    placeholder='Order in execution (numeric value only between 1 and 100)'
                                    action={{ icon: 'add', onClick: this.addModule }}
                                    onChange={this.handleChange}
                                />

                                <Form.Field style={{ textAlign: 'center'}}>
                                    <label>Modules</label>
                                    <JSONInput
                                        id          = 'modules'
                                        reset = { false }
                                        onKeyPressUpdate = { false }
                                        placeholder = { this.state.modules }
                                        width = '100%'
                                        height = '100%'
                                        max-height = '400px'
                                        style={{ container: { textAlign: 'left', width: '100%' }}}
                                    />
                                </Form.Field>

                            </Form.Group>

                            <Form.Button onClick={this.submit}> Submit it </Form.Button>
                            <div>
                                {
                                this.state.error_popup_show?
                                    <p style={{color: "red"}}>Error : The form must be fully filled.</p>
                                    : null
                                }
                            </div>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
            </Container>
        )
    }

}


export default NewScenario;
