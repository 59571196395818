/*
**  Powered by WOPR Team's development
*/


import  axios           from "axios"


class JobsCTR {

    static async create(data) {
        console.debug("CREATEs JOB");
        try {
            let request = await axios.create({ method: "post", baseURL: process.env.REACT_APP_API_BASE_ROUTE, headers: { "Authorization": "Token " + localStorage.getItem("auth_token")} });
            await request.post("/jobs", data);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    }


    static async retrieve(job_id) {
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.get("/jobs/" + job_id, { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
            return response.data.job;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    static async list_running_jobs(pentest_id){
        try {
            let request = await axios.create({ baseURL: process.env.REACT_APP_API_BASE_ROUTE});
            let response = await request.get("/pentests/" + pentest_id + "/jobs", { headers: { "Authorization": "Token " + localStorage.getItem("auth_token")}});
            return response.data.jobs;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

}


export default JobsCTR;
