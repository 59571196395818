/*
**  Powered by WOPR Team's development
*/


import { SIGNIN, SIGNOUT, CHECK_AUTH }  from './types';


export const signin = (token) => ({
    type: SIGNIN,
    payload: {
        token: token
    },
});


export const signout = () => ({
    type: SIGNOUT,
    payload: {},
});


export const checkAuth = () => ({
    type: CHECK_AUTH,
    payload: {},
});
