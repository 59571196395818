/*
**  Powered by WOPR Team's development
*/


import  React, { Component }                from 'react'
import  { Divider }                         from 'semantic-ui-react'
import  NavBarLayout                        from "../../layouts/NavBarLayout"
import  { switchTab }                       from "../../../store/actions/routing";
import  { connect }                         from "react-redux"
import	{ Switch, Route }                   from "react-router-dom";
import  EpicList                            from "./EpicsList";


class Epics extends Component {

    componentDidMount() {
        this.props.switchTab('epics');
        this.props.history.push('/epics');
    }

    render() {
        return (
            <NavBarLayout>
                <Divider hidden />
                <Switch>
                    <Route exact path="/epics" component={EpicList} />
                </Switch>
                <Divider hidden />
            </NavBarLayout>
        )
    }
}


export default connect((state) => { return { routing: state.routing }}, { switchTab })(Epics);
